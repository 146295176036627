import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_SELF_ERRORS,
	UPLOAD_FILE_ERROR,
	ALL_FILES_LOADING,
	ALL_FILES_GET,
	ALL_FILES_ERROR,
	FILE_INFO_LOADING,
	FILE_INFO_GET,
	FILE_INFO_ERROR,
	DOWNLOAD_FILE_LOADING,
	FILE_CHECK_LOADING,
	FILE_CHECK_GET,
	FILE_CHECK_ERROR,
	DOWNLOAD_VALIDATION_LOADING,
	FILE_CONFIRM_LOADING,
	FILE_DISCARD_LOADING,
	LOAD_CREATE_LOADING,
	LOAD_CREATE_ERROR,
	ALL_LOADS_LOADING,
	ALL_LOADS_GET,
	ALL_LOADS_ERROR,
	LOAD_UPDATE_LOADING,
	LOAD_UPDATE_ERROR,
	LOAD_DELETE_LOADING,
	LOADS_DATA_LOADING,
	LOADS_DATA_GET,
	LOADS_DATA_ERROR
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_self_errors = () => dispatch => {
	dispatch({
		type: CLEAR_SELF_ERRORS
	});
}

export const upload_file = (messageApi, body = {}) => dispatch => {
	dispatch(clear_self_errors());
	const hideLoadingMsg = messageApi.loading(i18next.t('loadFile.msg.loading'), 0);
	
	let url = process.env.REACT_APP_SERVER_URL + '/api/self/files/upload';

	const formData = new FormData();
	formData.append('organization', body.organization);
	formData.append('workspace', body.workspace);
	formData.append('module', body.module);
	if (body.cycle) {
		formData.append('cycle', body.cycle);
	}
	if (body.load) {
		formData.append('load', body.load);
	}
	formData.append('source', 2);
	formData.append('type', body.type);
	formData.append('name', body.name);
	formData.append('description', body.description);
	formData.append('file', body.file);

	Axios.post(url, formData)
	.then((res) => {
		hideLoadingMsg();
		messageApi.success(i18next.t('loadFile.msg.success'), 4);
	}).catch((err) => {
		// console.log(err);
		hideLoadingMsg();
		// messageApi.error(i18next.t('loadFile.msg.error'), 4);
		dispatch({
			type: UPLOAD_FILE_ERROR,
			payload: { type: 'upload_file', msg: err.response.data }
		});
	})
}

export const get_all_files = (filters) => dispatch => {
	dispatch({
		type: ALL_FILES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/files?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newFilesArray = add_key_prop(res.data.files);
		res.data.files = newFilesArray;

		dispatch({
			type: ALL_FILES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_FILES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_FILES_ERROR,
			payload: { type: 'all_files', msg: err.message }
		});

		dispatch({
			type: ALL_FILES_LOADING,
			payload: false
		});
	})
}

export const get_file_info = (file_id) => dispatch => {
	dispatch({
		type: FILE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/files/${file_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: FILE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: FILE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: FILE_INFO_ERROR,
			payload: { type: 'file_info', msg: err.message }
		});

		dispatch({
			type: FILE_INFO_LOADING,
			payload: false
		});
	})
}

export const download_file = (messageApi, file_id, fileName) => dispatch => {
	dispatch({
		type: DOWNLOAD_FILE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/files/${file_id}/data`;

	Axios.request({
		url: url,
		method: 'GET',
		responseType: 'blob',
	}).then((res) => {
		dispatch({
			type: DOWNLOAD_FILE_LOADING,
			payload: false
		});

		const downloadURL = window.URL.createObjectURL(new Blob([ res.data ]));
		const link = document.createElement('a');
		link.href = downloadURL;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('loadStatusDetails.download.error'), 4);

		dispatch({
			type: DOWNLOAD_FILE_LOADING,
			payload: false
		});
	})
}

export const check_file = (showLoading, file_id) => dispatch => {
	if (showLoading) {
		dispatch({
			type: FILE_CHECK_LOADING,
			payload: true
		});
	}

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/files/${file_id}/check`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: FILE_CHECK_GET,
			payload: res.data
		});

		dispatch({
			type: FILE_CHECK_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: FILE_CHECK_ERROR,
			payload: { type: 'check_file', msg: err.message }
		});

		dispatch({
			type: FILE_CHECK_LOADING,
			payload: false
		});
	})
}

export const download_validation_file = (messageApi, file_id, fileName) => dispatch => {
	dispatch({
		type: DOWNLOAD_VALIDATION_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/files/${file_id}/validation`;

	Axios.request({
		url: url,
		method: 'GET',
		responseType: 'blob',
	}).then((res) => {
		dispatch({
			type: DOWNLOAD_VALIDATION_LOADING,
			payload: false
		});

		const downloadURL = window.URL.createObjectURL(new Blob([ res.data ]));
		const link = document.createElement('a');
		link.href = downloadURL;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('loadStatusDetails.download.error'), 4);

		dispatch({
			type: DOWNLOAD_VALIDATION_LOADING,
			payload: false
		});
	})
}

export const confirm_file = (messageApi, file_id) => dispatch => {
	dispatch({
		type: FILE_CONFIRM_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/files/${file_id}/confirm`;

	Axios.post(url)
	.then((res) => {
		// console.log(res.data);
		messageApi.success(i18next.t('loadStatusDetails.confirm.msg.success'), 4);

		dispatch({
			type: FILE_CONFIRM_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('loadStatusDetails.confirm.msg.error'), 4);

		dispatch({
			type: FILE_CONFIRM_LOADING,
			payload: false
		});
	})
}

export const discard_file = (messageApi, file_id, filters) => dispatch => {
	dispatch({
		type: FILE_DISCARD_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/files/${file_id}/discard`;

	Axios.delete(url)
	.then((res) => {
		// console.log(res.data);
		messageApi.success(i18next.t('loadStatusDetails.discard.msg.success'), 4);

		dispatch({
			type: FILE_DISCARD_LOADING,
			payload: false
		});

		if (filters) {
			dispatch(get_all_files(filters));
		}
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('loadStatusDetails.discard.msg.error'), 4);

		dispatch({
			type: FILE_DISCARD_LOADING,
			payload: false
		});
	})
}

export const load_create = (messageApi, loadInfo, handleCancel, filters) => dispatch => {
	dispatch({
		type: LOAD_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/loads/create`;
	
	const body = {
		...loadInfo,
		source: 2
	};

	Axios.post(url, body)
	.then((res) => {
		messageApi.success(i18next.t('loadForm.create.msg.success'), 4);
		
		handleCancel();

		dispatch({
			type: LOAD_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_loads(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOAD_CREATE_ERROR,
			payload: { type: 'load_create', msg: err.message }
		});

		dispatch({
			type: LOAD_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_loads = (filters) => dispatch => {
	dispatch({
		type: ALL_LOADS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/loads?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newLoadsArray = add_key_prop(res.data.loads);
		res.data.loads = newLoadsArray;

		dispatch({
			type: ALL_LOADS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_LOADS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_LOADS_ERROR,
			payload: { type: 'all_loads', msg: err.message }
		});

		dispatch({
			type: ALL_LOADS_LOADING,
			payload: false
		});
	})
}

export const load_update = (messageApi, loadId, loadInfo, handleCancel, filters) => dispatch => {
	dispatch({
		type: LOAD_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/loads/${loadId}/update`;

	Axios.put(url, loadInfo)
	.then((res) => {
		messageApi.success(i18next.t('loadForm.edit.msg.success'), 4);
		
		handleCancel();

		dispatch({
			type: LOAD_UPDATE_LOADING,
			payload: false
		});

		dispatch(get_all_loads(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOAD_UPDATE_ERROR,
			payload: { type: 'load_update', msg: err.message }
		});

		dispatch({
			type: LOAD_UPDATE_LOADING,
			payload: false
		});
	})
}

export const load_delete = (messageApi, loadId, filters) => dispatch => {
	dispatch({
		type: LOAD_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/loads/${loadId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('loadForm.delete.msg.success'), 4);

		dispatch({
			type: LOAD_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_loads(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('loadForm.delete.msg.error'), 4);

		dispatch({
			type: LOAD_DELETE_LOADING,
			payload: false
		});
	})
}

export const get_loads_data = (filters) => dispatch => {
	dispatch({
		type: LOADS_DATA_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/loads/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: LOADS_DATA_GET,
			payload: res.data
		});

		dispatch({
			type: LOADS_DATA_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: LOADS_DATA_ERROR,
			payload: { type: 'loads_data', msg: err.message }
		});

		dispatch({
			type: LOADS_DATA_LOADING,
			payload: false
		});
	})
}

export const file_check_retry = (messageApi, file_info) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/self/files/check';

	const body = {
		organization: file_info.organization.$oid,
		file: file_info._id.$oid
	}

	Axios.post(url, body)
	.then((res) => {
		// console.log(res.data);
		messageApi.success('Checking file', 4);
	}).catch((err) => {
		// console.log(err);
		messageApi.error(err.message, 4);
	})
}

export const file_process = (messageApi, file_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/self/files/process';

	const body = {
		file: file_id
	}

	Axios.post(url, body)
	.then((res) => {
		// console.log(res.data);
		messageApi.success('Processing file', 4);
	}).catch((err) => {
		// console.log(err);
		messageApi.error(err.message, 4);
	})
}
