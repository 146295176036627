import React from 'react';

// Packages
import { BrowserRouter as  Router, Routes, Route, Navigate  } from 'react-router-dom';
import { ConfigProvider, Layout, message } from 'antd';

// Components
import Header from './components/main/Header';
import Footer from './components/main/Footer';
import Authentication from './components/main/Authentication';
import PrivateRoute from './router/PrivateRoute';
import OrgRoutes from './components/routes/OrgRoutes';
import Locations from './components/locations/Locations';
import Loads from './components/load/loads/Loads';
import LoadFiles from './components/load/files/LoadFiles';
import LoadStatus from './components/load/status/LoadStatus';
import LoadFileInfo from './components/load/files/LoadFileInfo';
import Errors from './components/self/errors/Errors';
import Schemas from './components/self/schemas/Schemas';
import Configurations from './components/self/configurations/Configurations';
import Cycles from './components/cycles/Cycles';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Actions
import { user_token_check } from './redux/actions/authActions';

user_token_check (store);

const App = () => {
	const [messageApi, contextHolder] = message.useMessage();

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#3e619b',
					colorPrimaryBg: 'white'
				},
				components: {
					Table: {
						rowSelectedBg: '#C0C7CD'
					}
				}
			}}
		>
			<Provider store= { store }>
				<Router>
					<Layout>
						{contextHolder}

						<Header />

						<Layout.Content className='content'>
							<Routes>
								<Route path='/auth' element={ <Authentication /> } exact />

								<Route element={ <PrivateRoute /> } exact>
									{/* Load */}
									<Route path='/loads' element={ <Loads messageApi={messageApi} /> } exact />
									<Route path='/load-files' element={ <LoadFiles messageApi={messageApi} /> } exact />
									<Route path='/load-status' element={ <LoadStatus messageApi={messageApi} /> } exact />
									<Route path='/load-file/:file_id/info' element={ <LoadFileInfo messageApi={messageApi} /> } exact />

									{/* Routes */}
									<Route path='/routes' element={ <OrgRoutes messageApi={messageApi} /> } exact />

									{/* Locations */}
									<Route path='/route/:route_id/locations' element={ <Locations messageApi={messageApi} /> } exact />

									{/* Errors */}
									<Route path='/errors' element={ <Errors messageApi={messageApi} /> } exact />

									{/* Schemas */}
									<Route path='/schemas' element={ <Schemas messageApi={messageApi} /> } exact />

									{/* Configurations */}
									<Route path='/configurations' element={ <Configurations messageApi={messageApi} /> } exact />

									{/* Cycles */}
									<Route path='/cycles' element={ <Cycles messageApi={messageApi} /> } exact />
								</Route>

								{/* Other */}
								<Route path='*' element={ <Navigate to='/load-files' replace /> } />
							</Routes>
						</Layout.Content>

						<Footer/>
					</Layout>
				</Router>
			</Provider>
		</ConfigProvider>
	);
}

export default App;
