import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_SELF_SCHEMAS_ERRORS,
	SELF_SCHEMA_CREATE_LOADING,
	SELF_SCHEMA_CREATE_ERROR,
	ALL_SELF_SCHEMAS_LOADING,
	ALL_SELF_SCHEMAS_GET,
	ALL_SELF_SCHEMAS_ERROR,
	SELF_SCHEMA_UPDATE_LOADING,
	SELF_SCHEMA_UPDATE_ERROR,
	SELF_SCHEMA_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_self_schemas_errors = () => dispatch => {
	dispatch({
		type: CLEAR_SELF_SCHEMAS_ERRORS
	});
}

export const self_schema_create = (messageApi, selfSchemaBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: SELF_SCHEMA_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/schemas/create';

	Axios.post(url, selfSchemaBody)
	.then((res) => {
		messageApi.success(i18next.t('schemasForm.create.msg.success'), 4);

		handleCancel();

		dispatch({
			type: SELF_SCHEMA_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_self_schemas(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SELF_SCHEMA_CREATE_ERROR,
			payload: { type: 'self_schema_create', msg: err.message }
		});

		dispatch({
			type: SELF_SCHEMA_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_self_schemas = (filters) => dispatch => {
	dispatch({
		type: ALL_SELF_SCHEMAS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/schemas?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newSchemasArray = add_key_prop(res.data.schemas);
		res.data.schemas = newSchemasArray;

		dispatch({
			type: ALL_SELF_SCHEMAS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_SELF_SCHEMAS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_SELF_SCHEMAS_ERROR,
			payload: { type: 'all_self_schemas', msg: err.message }
		});

		dispatch({
			type: ALL_SELF_SCHEMAS_LOADING,
			payload: false
		});
	})
}

export const self_schema_update = (messageApi, selfSchemaId, selfSchemaBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: SELF_SCHEMA_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/schemas/${selfSchemaId}/update`;

	Axios.put(url, selfSchemaBody)
	.then((res) => {
		messageApi.success(i18next.t('schemasForm.edit.msg.success'), 4);
		
		handleCancel();

		dispatch({
			type: SELF_SCHEMA_UPDATE_LOADING,
			payload: false
		});

		dispatch(get_all_self_schemas(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SELF_SCHEMA_UPDATE_ERROR,
			payload: { type: 'self_schema_update', msg: err.message }
		});

		dispatch({
			type: SELF_SCHEMA_UPDATE_LOADING,
			payload: false
		});
	})
}

export const self_schema_delete = (messageApi, selfSchemaId, filters) => dispatch => {
	dispatch({
		type: SELF_SCHEMA_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/schemas/${selfSchemaId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('schemasForm.delete.msg.success'), 4);

		dispatch({
			type: SELF_SCHEMA_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_self_schemas(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('schemasForm.delete.msg.error'), 4);

		dispatch({
			type: SELF_SCHEMA_DELETE_LOADING,
			payload: false
		});
	})
}
