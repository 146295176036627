import {
	CLEAR_SELF_ERRORS_ERRORS,
	SELF_ERROR_CREATE_LOADING,
	SELF_ERROR_CREATE_ERROR,
	ALL_SELF_ERRORS_LOADING,
	ALL_SELF_ERRORS_GET,
	ALL_SELF_ERRORS_ERROR,
	SELF_ERROR_UPDATE_LOADING,
	SELF_ERROR_UPDATE_ERROR,
	SELF_ERROR_DELETE_LOADING
} from '../types';

const initialState = {
	self_error_create_loading: false,
	all_self_errors_loading: false,
	all_self_errors: { count: 0, errors: [] },
	self_error_update_loading: false,
	self_error_delete_loading: false,
	self_errors_errors: {}
}

export default function selfErrorsReducer(state = initialState, action){
	switch(action.type){
		case CLEAR_SELF_ERRORS_ERRORS:
			return {
				...state,
				self_errors_errors: {}
			}
		case SELF_ERROR_CREATE_LOADING:
			return {
				...state,
				self_error_create_loading: action.payload
			}
		case ALL_SELF_ERRORS_LOADING:
			return {
				...state,
				all_self_errors_loading: action.payload
			}
		case ALL_SELF_ERRORS_GET:
			return {
				...state,
				all_self_errors: {
					count: action.payload.count,
					errors: action.payload.errors
				}
			}
		case SELF_ERROR_UPDATE_LOADING:
			return {
				...state,
				self_error_update_loading: action.payload
			}
		case SELF_ERROR_DELETE_LOADING:
			return {
				...state,
				self_error_delete_loading: action.payload
			}
		case SELF_ERROR_CREATE_ERROR:
		case ALL_SELF_ERRORS_ERROR:
		case SELF_ERROR_UPDATE_ERROR:
			return {
				...state,
				self_errors_errors: {
					...state.self_errors_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
