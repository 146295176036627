import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_cycles, get_modules, get_workspaces } from '../../../redux/actions/organizationActions';
import { self_config_create, self_config_update } from '../../../redux/actions/selfConfigActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { load_scope_options } from '../../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';

const ConfigFormModal = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, orgFilters, update, configToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	const { self_config_create_loading, self_config_update_loading, self_configs_errors } = useSelector(state => state.self_config);
	
	const [form] = Form.useForm();
	const [loadScopeOptions, setloadScopeOptions] = useState([]);

	const LOAD_SCOPES = load_scope_options();

	useEffect(() => {
		setloadScopeOptions(create_select_options_from_array(LOAD_SCOPES));
	}, []);

	useEffect(() => {
		if (!isEmpty(configToUpdate)) {
			form.setFieldsValue({
				name: configToUpdate.name,
				description: configToUpdate.description,
				bq_project: configToUpdate.bq_project,
				bq_table: configToUpdate.bq_table,
				bq_table_prefix: configToUpdate.bq_table_prefix
			});
		}
	}, [configToUpdate]);

	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.organization) {
			form.setFieldsValue({ workspace: undefined, module: undefined, cycle: undefined });

			dispatch(get_workspaces({ organization: changedValues.organization }));
			dispatch(get_modules({ organization: changedValues.organization }));
		}

		if (changedValues.workspace) {
			form.setFieldsValue({ module: undefined, cycle: undefined });
		}
		
		if (changedValues.module) {
			form.setFieldsValue({ cycle: undefined });

			if (allValues.workspace) {
				dispatch(get_cycles({ workspace: allValues.workspace, module: changedValues.module }));
			}
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					values = {
						...values,
						app: null,
						organization: values.organization ?? orgFilters.organization,
						workspace: values.workspace ?? orgFilters.workspace,
						module: values.module ?? orgFilters.module
					};

					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(self_config_create(messageApi, values, handleCancel, filters));
				}
				else {
					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(self_config_update(messageApi, configToUpdate?._id?.$oid, values, handleCancel, filters));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('configForm.create.title') : t('configForm.edit.title')}
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={[
				<Button key='back' onClick={handleCancel}>
					{t('configForm.cancel')}
				</Button>,
				<Button
					key='submit'
					type='primary'
					loading={!update ? self_config_create_loading : self_config_update_loading}
					onClick={onOk}
				>
					{!update ? t('configForm.create.title').split(' ')[0] : t('configForm.edit.title').split(' ')[0]}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
				labelWrap
				onValuesChange={onValuesChange}
			>
				{self_configs_errors.hasOwnProperty('self_config_create') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('configForm.create.msg.error')} type='error' showIcon />
					</div>
				}

				{self_configs_errors.hasOwnProperty('self_config_update') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('configForm.edit.msg.error')} type='error' showIcon />
					</div>
				}

				{!update &&
					<Fragment>
						{orgFilters.organization === '' &&
							<Form.Item
								label={t('filters.organization')}
								name='organization'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.organization'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.organization')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(organizations, 'name')}
								/>
							</Form.Item>
						}
						{orgFilters.workspace === '' &&
							<Form.Item
								label={t('filters.workspace')}
								name='workspace'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.workspace'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.workspace')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(workspaces, 'name')}
								/>
							</Form.Item>
						}
						{orgFilters.module === '' &&
							<Form.Item
								label={t('filters.module')}
								name='module'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.module'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.module')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(modules, 'name')}
								/>
							</Form.Item>
						}
						<Form.Item
							label={t('schemasForm.inputs.scope')}
							name='scope'
							rules={[
								{
									required: true,
									message: t('schemasForm.validationMsg.scope'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={t('schemasForm.placeholder.scope')}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={loadScopeOptions}
							/>
						</Form.Item>
					</Fragment>
				}
				<Form.Item
					label={t('routesTable.headers.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.name'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.name')} />
				</Form.Item>
				<Form.Item
					label={t('loadFileInput.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.description'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.description')} />
				</Form.Item>
				<Form.Item
					label={t('configForm.inputs.bq_project')}
					name='bq_project'
					rules={[
						{
							required: true,
							message: t('configForm.validationMsg.bq_project'),
						}
					]}
				>
					<Input placeholder={t('configForm.placeholder.bq_project')} />
				</Form.Item>
				<Form.Item
					label={t('configForm.inputs.bq_table')}
					name='bq_table'
					rules={[
						{
							required: true,
							message: t('configForm.validationMsg.bq_table'),
						}
					]}
				>
					<Input placeholder={t('configForm.placeholder.bq_table')} />
				</Form.Item>
				<Form.Item
					label={t('configForm.inputs.bq_table_prefix')}
					name='bq_table_prefix'
					rules={[
						{
							required: true,
							message: t('configForm.validationMsg.bq_table_prefix'),
						}
					]}
				>
					<Input placeholder={t('configForm.placeholder.bq_table_prefix')} />
				</Form.Item>
			</Form>
		</Modal>
	)
}

ConfigFormModal.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	configToUpdate: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default ConfigFormModal;
