import Axios from 'axios';

/**
 * @param {string} location_id - Location id.
 * @param {string} workspace_id - Workspace id
 */
const get_location_data = async (location_id, workspace_id) => {
  try {
    let url = process.env.REACT_APP_SERVER_URL + `/api/locations/${location_id}/data?workspace=${workspace_id}`;
    const res = await Axios.get(url);
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

/**
 * @param {Object} route_info - Objet with route info locations array.
 */
export const add_location_data = async (route_info) => {
  try {
    const route_locations_data = await Promise.all(
      route_info.locations.map(async (location) => {
        try {
          const location_data = await get_location_data(location._id.$oid, route_info.workspace.$oid);
          return { ...location, data: location_data.data };
        } catch (error) {
          // Error getting location data
          console.error(`Location with id ${location._id.$oid} -`, error.message);
          return { ...location, data: {} };
        }
      })
    );
    return route_locations_data;
  } catch (error) {
    console.error('Error processing locations:', error);
    throw error;
  }
};

