import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_cycles, get_modules, get_workspaces } from '../../../redux/actions/organizationActions';
import { load_create, load_update, clear_self_errors } from '../../../redux/actions/selfActions';

// Utils
import { load_types_options } from '../../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';
import isEmpty from '../../../utils/isEmpty';

const LoadModal = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, orgFilters, update, loadToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { organizations, workspaces, modules, cycles } = useSelector(state => state.organization);

	const { load_create_loading, self_errors } = useSelector(state => state.self);
	
	const [form] = Form.useForm();
	const [loadTypesOptions, setloadTypesOptions] = useState([]);

	const LOAD_TYPES = load_types_options();

	useEffect(() => {
		setloadTypesOptions(create_select_options_from_array(LOAD_TYPES));
	}, []);

	useEffect(() => {
		if (!isEmpty(loadToUpdate)) {
			form.setFieldsValue({
				name: loadToUpdate.name,
				description: loadToUpdate.description
			});
		}
	}, [loadToUpdate]);

	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
		dispatch(clear_self_errors());
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.organization) {
			form.setFieldsValue({ workspace: undefined, module: undefined, cycle: undefined });

			dispatch(get_workspaces({ organization: changedValues.organization }));
			dispatch(get_modules({ organization: changedValues.organization }));
		}

		if (changedValues.workspace) {
			form.setFieldsValue({ module: undefined, cycle: undefined });
		}
		
		if (changedValues.module) {
			form.setFieldsValue({ cycle: undefined });

			if (allValues.workspace) {
				dispatch(get_cycles({ workspace: allValues.workspace, module: changedValues.module }));
			}
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					values = {
						...values,
						organization: values.organization ?? orgFilters.organization,
						workspace: values.workspace ?? orgFilters.workspace,
						module: values.module ?? orgFilters.module
					};
					
					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(load_create(messageApi, values, handleCancel, filters));
				}
				else {
					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(load_update(messageApi, loadToUpdate?._id?.$oid, values, handleCancel, filters))
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('loadForm.create.title') : t('loadForm.edit.title')}
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={[
				<Button key='back' onClick={handleCancel}>
					{t('loadForm.cancel')}
				</Button>,
				<Button key='submit' type='primary' loading={load_create_loading} onClick={onOk}>
					{!update ? t('loadForm.create.title').split(' ')[0] : t('loadForm.edit.title').split(' ')[0]}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 4 }}
				form={form}
				onValuesChange={onValuesChange}
			>
				{self_errors.hasOwnProperty('load_create') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('loadForm.create.msg.error')} type='error' showIcon />
					</div>
				}

				{self_errors.hasOwnProperty('load_update') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('loadForm.edit.msg.error')} type='error' showIcon />
					</div>
				}

				{!update &&
					<Fragment>
						{orgFilters.organization === '' &&
							<Form.Item
								label={t('filters.organization')}
								name='organization'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.organization'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.organization')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(organizations, 'name')}
								/>
							</Form.Item>
						}
						{orgFilters.workspace === '' &&
							<Form.Item
								label={t('filters.workspace')}
								name='workspace'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.workspace'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.workspace')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(workspaces, 'name')}
								/>
							</Form.Item>
						}
						{orgFilters.module === '' &&
							<Form.Item
								label={t('filters.module')}
								name='module'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.module'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.module')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(modules, 'name')}
								/>
							</Form.Item>
						}
						<Form.Item
							label={t('filters.cycle')}
							name='cycle'
							rules={[
								{
									required: true,
									message: t('loadForm.validationMsg.cycle'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={t('loadForm.placeholder.cycle')}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(cycles, 'name')}
							/>
						</Form.Item>
						<Form.Item
							label={t('loadFileInput.loadType')}
							name='type'
							rules={[
								{
									required: true,
									message: t('loadForm.validationMsg.type'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={t('loadForm.placeholder.type')}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={loadTypesOptions}
							/>
						</Form.Item>
					</Fragment>
				}
				<Form.Item
					label={t('routesTable.headers.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('loadForm.validationMsg.name'),
						}
					]}
				>
					<Input placeholder={t('loadForm.placeholder.name')} />
				</Form.Item>
				<Form.Item
					label={t('loadFileInput.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('loadForm.validationMsg.description'),
						}
					]}
				>
					<Input placeholder={t('loadForm.placeholder.description')} />
				</Form.Item>
			</Form>
		</Modal>
	)
}

LoadModal.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	loadToUpdate: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default LoadModal;
