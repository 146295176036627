import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_SELF_ERRORS_ERRORS,
	SELF_ERROR_CREATE_LOADING,
	SELF_ERROR_CREATE_ERROR,
	ALL_SELF_ERRORS_LOADING,
	ALL_SELF_ERRORS_GET,
	ALL_SELF_ERRORS_ERROR,
	SELF_ERROR_UPDATE_LOADING,
	SELF_ERROR_UPDATE_ERROR,
	SELF_ERROR_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_self_errors_errors = () => dispatch => {
	dispatch({
		type: CLEAR_SELF_ERRORS_ERRORS
	});
}

export const self_error_create = (messageApi, selfErrorBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: SELF_ERROR_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/errors/create';

	Axios.post(url, selfErrorBody)
	.then((res) => {
		messageApi.success(i18next.t('errorForm.create.msg.success'), 4);

		handleCancel();

		dispatch({
			type: SELF_ERROR_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_self_errors(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SELF_ERROR_CREATE_ERROR,
			payload: { type: 'self_error_create', msg: err.message }
		});

		dispatch({
			type: SELF_ERROR_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_self_errors = (filters) => dispatch => {
	dispatch({
		type: ALL_SELF_ERRORS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/errors?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newErrorsArray = add_key_prop(res.data.errors);
		res.data.errors = newErrorsArray;

		dispatch({
			type: ALL_SELF_ERRORS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_SELF_ERRORS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_SELF_ERRORS_ERROR,
			payload: { type: 'all_self_errors', msg: err.message }
		});

		dispatch({
			type: ALL_SELF_ERRORS_LOADING,
			payload: false
		});
	})
}

export const self_error_update = (messageApi, selfErrorId, selfErrorBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: SELF_ERROR_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/errors/${selfErrorId}/update`;

	Axios.put(url, selfErrorBody)
	.then((res) => {
		messageApi.success(i18next.t('errorForm.edit.msg.success'), 4);
		
		handleCancel();

		dispatch({
			type: SELF_ERROR_UPDATE_LOADING,
			payload: false
		});

		dispatch(get_all_self_errors(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SELF_ERROR_UPDATE_ERROR,
			payload: { type: 'self_error_update', msg: err.message }
		});

		dispatch({
			type: SELF_ERROR_UPDATE_LOADING,
			payload: false
		});
	})
}

export const self_error_delete = (messageApi, selfErrorId, filters) => dispatch => {
	dispatch({
		type: SELF_ERROR_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/errors/${selfErrorId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('errorForm.delete.msg.success'), 4);

		dispatch({
			type: SELF_ERROR_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_self_errors(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('errorForm.delete.msg.error'), 4);

		dispatch({
			type: SELF_ERROR_DELETE_LOADING,
			payload: false
		});
	})
}
