import moment from 'moment-timezone';
import 'moment/locale/es';

const epoch_to_local_date = (epoch, locale) => {
	let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	moment.locale(locale);
	let momentTime = moment(epoch).tz(timezone).format('L LTS');

	return momentTime;
}

const cycle_days_info = (startDate, endDate) => {
	let start = moment(startDate);
	let end = moment(endDate);
	let today = moment();

	let cycleDays = end.diff(start, 'days') + 1;

	let daysOff = 0;
	for (let day = start; day.isBefore(end); day.add(1, 'days')) {
		if (day.day() === 0) {
			daysOff++;
		}
	}

	start = moment(startDate);
	let currentDays = -1;
	for (let day = start; day.isBefore(today); day.add(1, 'days')) {
		if (day.day() !== 0) {
			currentDays++;
		}
	}

	let workingDays = cycleDays - daysOff;
	let remainingDays = workingDays - currentDays;

	return [workingDays, currentDays, remainingDays];
}

export { epoch_to_local_date, cycle_days_info };
