import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Col, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../filters/OrgFilters';
import AreaFilters from '../filters/AreaFilters';
import RoutesTable from './RoutesTable';

// Actions
import { get_organizations, get_workspaces, get_modules, get_cycles, set_org_filters } from '../../redux/actions/organizationActions';

const OrgRoutes = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { user } = useSelector(state => state.auth);

	const { organizations, cycles } = useSelector(state => state.organization);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: '', route: '' });

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let _organization = '';

		if (organizations.length <= 1) {
			_organization = user.organization;
		}
		else {
			_organization = _ls?.organization;
		}

		const initialOrgFilters = {
			...orgFilters,
			organization: _organization,
			workspace: _ls?.workspace || '',
			module: _ls?.module || ''
		};
		
		setOrgFilters(initialOrgFilters);
		dispatch(set_org_filters(initialOrgFilters));
	}, [organizations]);

	useEffect(() => {
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}

		if (orgFilters.workspace !== '' && orgFilters.module !== '') {
			dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
		}
	}, [orgFilters]);


	return (
		<Row>
			<Col span={24}>
				<Typography.Title level={2}>{t('orgRoutes.title')}</Typography.Title>

				<OrgFilters
					orgFilters={orgFilters}
					setOrgFilters={setOrgFilters}
					displayFilters={{ organization: true, workspace: true, module: true }}
				/>

				<Divider />

				<Typography style={{ textAlign: 'center' }}><i className='bi bi-globe2 me-2'></i>{t('filters.areas')}</Typography>
				<AreaFilters
					orgFilters={orgFilters}
					areaFilters={areaFilters}
					setAreaFilters={setAreaFilters}
				/>

				<RoutesTable messageApi={messageApi} />
			</Col>
		</Row>
	)
}

OrgRoutes.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default OrgRoutes;
