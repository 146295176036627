import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../../filters/OrgFilters';
import SchemasFormModal from './SchemasFormModal';
import SchemasTable from './SchemasTable';

// Actions
import { get_all_self_schemas } from '../../../redux/actions/selfSchemasActions';

const Schemas = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [update, setUpdate] = useState(false);
	const [schemaToUpdate, setSchemaToUpdate] = useState({});
	const [pageSize] = useState(0);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '' && orgFilters.module !== '') {
			const filters = { ...orgFilters, skip: 0, limit: 0 };
			dispatch(get_all_self_schemas(filters));
		}
	}, [orgFilters]);

	const createSchema = () => {
		setIsModalOpen(true);
		setUpdate(false);
		setSchemaToUpdate({});
	}

	return (
		<Row justify='center'>
			<Col lg={24} md={24} sm={24} xs={24}>
				<SchemasFormModal
					messageApi={messageApi}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					orgFilters={orgFilters}
					update={update}
					schemaToUpdate={schemaToUpdate}
					pageSize={pageSize}
					skip={skip}
				/>

				<Row justify='center'>
					<Col lg={8} md={8} sm={24} xs={24}></Col>
					<Col lg={8} md={8} sm={24} xs={24}>
						<Typography.Title className='text-center' level={2}>{t('schemas.title')}</Typography.Title>
					</Col>
					<Col style={{ textAlign: 'end' }} lg={8} md={8} sm={24} xs={24}>
						<Button
							type='primary'
							size='large'
							onClick={() => createSchema()}
						>
							{t('loadForm.create.title').split(' ')[0]}
						</Button>
					</Col>
				</Row>

				<Row justify='center'>
					<Col lg={17} md={17} sm={24} xs={24}>
						<OrgFilters
							orgFilters={orgFilters}
							setOrgFilters={setOrgFilters}
							displayFilters={{ organization: true, workspace: true, module: true }}
						/>
					</Col>
				</Row>

				<SchemasTable
					messageApi={messageApi}
					orgFilters={orgFilters}
					setIsModalOpen={setIsModalOpen}
					setUpdate={setUpdate}
					setSchemaToUpdate={setSchemaToUpdate}
					pageSize={pageSize}
					skip={skip}
					setSkip={setSkip}
				/>
			</Col>
		</Row>
	);
}

Schemas.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Schemas;
