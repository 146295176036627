import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

// Actions
import { clear_cycles_errors, cycle_create, cycle_update } from '../../redux/actions/cyclesActions';

// Utils
import isEmpty from '../../utils/isEmpty';
import { area_type_options, cycle_scope_options } from '../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../utils/create_select_options';
import { get_depots, get_groups, get_regions, get_zones } from '../../redux/actions/areasActions';
import { get_routes } from '../../redux/actions/routesActions';

const CyclesForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, orgFilters, update, cycleToUpdate, setCycleToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { regions, zones, depots, groups } = useSelector(state => state.areas);

	const { routes } = useSelector(state => state.routes);

	const { cycle_create_loading, cycle_update_loading, cycles_errors } = useSelector(state => state.cycles);
	
	const [form] = Form.useForm();
	const [areaType, setAreaType] = useState('');
	const [showReferenceIput, setShowReferenceIput] = useState(false);
	const [areaTypesOptions, setAreaTypesOptions] = useState([]);
	const [cycleScopeOptions, setCycleScopeOptions] = useState([]);
	const [referenceOptions, setReferenceOptions] = useState([]);

	const AREA_TYPES = area_type_options();
	const CYCLE_SCOPE = cycle_scope_options();

	useEffect(() => {
		setAreaTypesOptions(create_select_options_from_array(AREA_TYPES));
		setCycleScopeOptions(create_select_options_from_array(CYCLE_SCOPE));
	}, []);

	useEffect(() => {
		if (orgFilters.organization && orgFilters.workspace) {
			dispatch(get_regions(orgFilters));
			dispatch(get_zones(orgFilters));
			dispatch(get_depots(orgFilters));
			dispatch(get_groups(orgFilters));
			dispatch(get_routes({ ...orgFilters, skip: 0, limit: 0 }));
		}
	}, [orgFilters]);

	useEffect(() => {
		if (!isEmpty(cycleToUpdate)) {
			let areaInput = null;

			if (cycleToUpdate.area_type === 2) {
				const region = regions.regions.find(region => region._id.$oid === cycleToUpdate.region?.$oid);
				areaInput = { value: region._id.$oid, label: region.name };
				setReferenceOptions(create_select_options(regions.regions, 'name'));
			}
			else if (cycleToUpdate.area_type === 3) {
				const zone = zones.zones.find(zone => zone._id.$oid === cycleToUpdate.zone?.$oid);
				areaInput = { value: zone._id.$oid, label: zone.name };
				setReferenceOptions(create_select_options(zones.zones, 'name'));
			}
			else if (cycleToUpdate.area_type === 4) {
				const depot = depots.depots.find(depot => depot._id.$oid === cycleToUpdate.depot?.$oid);
				areaInput = { value: depot._id.$oid, label: depot.name };
				setReferenceOptions(create_select_options(depots.depots, 'name'));
			}
			else if (cycleToUpdate.area_type === 5) {
				const group = groups.groups.find(group => group._id.$oid === cycleToUpdate.group?.$oid);
				areaInput = { value: group._id.$oid, label: group.name };
				setReferenceOptions(create_select_options(groups.groups, 'name'));
			}
			else if (cycleToUpdate.area_type === 6) {
				const route = routes.routes.find(route => route._id.$oid === cycleToUpdate.route?.$oid);
				areaInput = { value: route._id.$oid, label: route.name };
				setReferenceOptions(create_select_options(routes.routes, 'name'));
			}

			form.setFieldsValue({
				organization: orgFilters.organization,
				workspace: orgFilters.workspace,
				module: orgFilters.module,
				area_type: cycleToUpdate.area_type,
				area: areaInput,
				name: cycleToUpdate.name,
				description: cycleToUpdate.description,
				date: [dayjs(cycleToUpdate.start.$date), dayjs(cycleToUpdate.end.$date)],
				scope: cycleToUpdate.scope,
			});

			setAreaType(areaTypesOptions.find(option => option.value === form.getFieldValue('area_type'))?.label);
			cycleToUpdate.area_type === 1 ? setShowReferenceIput(false) : setShowReferenceIput(true);
		}
	}, [cycleToUpdate]);

	const handleCancel = () => {
		setIsModalOpen(false);
		setCycleToUpdate({});
		dispatch(clear_cycles_errors());
		setAreaType('');
		setShowReferenceIput(false);
		form.resetFields();
	}

  // Function to disable dates before today
	const disabledDate = (current) => {
		const minDate = dayjs();
		return current && current < minDate.startOf('day');
	};

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.area_type) {
			changedValues.area_type === 1 ? setShowReferenceIput(false) : setShowReferenceIput(true);

			switch (changedValues.area_type) {
				case 2: // Region
					setAreaType(t('filters.region'));
					setReferenceOptions(create_select_options(regions.regions, 'name'));
					break;
				case 3: // Zone
					setAreaType(t('filters.zone'));
					setReferenceOptions(create_select_options(zones.zones, 'name'));
					break;
				case 4: // Depot
					setAreaType(t('filters.depot'));
					setReferenceOptions(create_select_options(depots.depots, 'name'));
					break;
				case 5: // Group
					setAreaType(t('filters.group'));
					setReferenceOptions(create_select_options(groups.groups, 'name'));
					break;
				case 6: // Route
					setAreaType(t('filters.route'));
					setReferenceOptions(create_select_options(routes.routes, 'name'));
					break;
				default:
					setReferenceOptions([]);
					break
			}
		}
	}

	const onOk = () => {
		const filters = { ...orgFilters, skip: skip, limit: pageSize };

		form
			.validateFields()
			.then((values) => {
				const body = {
					organization: orgFilters.organization,
					workspace: orgFilters.workspace,
					module: orgFilters.module,
					area_type: values.area_type,
					name: values.name,
					description: values.description,
					start: dayjs(values.date[0]).startOf('day').valueOf(),
					end: dayjs(values.date[1]).endOf('day').valueOf(),
					scope: values.scope,
					...(values.area_type === 2 ? { region: values.area } : { region: null }),
					...(values.area_type === 3 ? { zone: values.area } : { zone: null }),
					...(values.area_type === 4 ? { depot: values.area } : { depot: null }),
					...(values.area_type === 5 ? { group: values.area } : { group: null }),
					...(values.area_type === 6 ? { route: values.area } : { route: null }),
				}

				if (!update) {
					// Create
					dispatch(cycle_create(messageApi, body, handleCancel, filters));
				} else {
					// Update
					dispatch(cycle_update(messageApi, cycleToUpdate?._id?.$oid, body, handleCancel, filters));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('cycle.create.title') : t('cycle.edit.title')}
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={[
				<Button key='back' onClick={handleCancel}>
					{t('loadForm.cancel')}
				</Button>,
				<Button key='submit' type='primary' loading={!update ? cycle_create_loading : cycle_update_loading} onClick={onOk}>
					{!update ? t('loadForm.create.title').split(' ')[0] : t('loadForm.edit.title').split(' ')[0]}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 4 }}
				form={form}
				onValuesChange={onValuesChange}
			>
				{cycles_errors.hasOwnProperty('cycle_create') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('cycle.create.error')} type='error' showIcon />
					</div>
				}

				{cycles_errors.hasOwnProperty('cycle_update') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('cycle.edit.error')} type='error' showIcon />
					</div>
				}
				<Form.Item
					label={t('routesTable.headers.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('loadForm.validationMsg.name'),
						}
					]}
				>
					<Input placeholder={t('loadForm.placeholder.name')} />
				</Form.Item>
				<Form.Item
					label={t('loadFileInput.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('loadForm.validationMsg.description'),
						}
					]}
				>
					<Input placeholder={t('loadForm.placeholder.description')} />
				</Form.Item>
				<Form.Item
					label={t('cycle.type')}
					name='area_type'
					rules={[
						{
							required: true,
							message: t('loadForm.validationMsg.type'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={t('loadForm.placeholder.type')}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={areaTypesOptions}
						disabled={update}
					/>
				</Form.Item>
				{showReferenceIput && (
					<Form.Item
						label={areaType}
						name='area'
						rules={[
							{
								required: true,
								message: t('cycle.validationMsg.reference'),
							}
						]}
					>
						<Select
							showSearch
							placeholder={t('cycle.placeholder.reference')}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={referenceOptions}
							disabled={update}
						/>
					</Form.Item>
				)}
				<Form.Item
					label='Scope'
					name='scope'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.scope'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={t('schemasForm.placeholder.scope')}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={cycleScopeOptions}
						disabled={update}
					/>
				</Form.Item>
				<Form.Item
					label={t('loadStatus.statusTable.date')}
					name='date'
					rules={[
						{
							required: true,
							message: t('cycle.validationMsg.date'),
						}
					]}
				>
					<DatePicker.RangePicker
						style={{ width: '100%' }}
						disabledDate={disabledDate}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}

CyclesForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	cycleToUpdate: PropTypes.object.isRequired,
	setCycleToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default CyclesForm;
