import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Button, Col, Row } from 'antd';
import PropTypes from 'prop-types';

// Components
import LocationsTable from './LocationsTable';
import RouteInfoCard from '../routes/RouteInfoCard';

// Actions
import { get_route_cycle, get_route_data, get_route_locations, load_route } from '../../redux/actions/routesActions';

// Utils
import isEmpty from '../../utils/isEmpty';
import { add_location_data } from '../../utils/locations';

const Locations = (props) => {
	const { messageApi } = props;

	const { route_id } = useParams();

	const dispatch = useDispatch();

	const { route_locations } = useSelector(state => state.routes);

	const [locations, setLocations] = useState([]);
	const [updatesMade, setUpdatesMade] = useState(false);
	
	useEffect(() => {
		dispatch(get_route_data(route_id));
		dispatch(get_route_locations(route_id));
		dispatch(get_route_cycle(route_id));
	}, []);
	
	useEffect(() => {
		if (!isEmpty(route_locations)) {
			const fetchLocationsData = async () => {
				try {
					const newLocationsArray = await add_location_data(route_locations);
					setLocations(newLocationsArray);
				} catch (error) {
					console.error('Error:', error);
				}
			};
	
			fetchLocationsData();
		}
	}, [route_locations]);

	return (
		<Row>
			<Col span={24}>
				<RouteInfoCard />

				<LocationsTable
					messageApi={messageApi}
					routeInfo={route_locations}
					locations={locations}
					updatesMade={updatesMade}
					setUpdatesMade={setUpdatesMade}
				/>
			</Col>
		</Row>
	)
}

Locations.propTypes = {
	messageApi: PropTypes.object.isRequired,
}

export default Locations;
