import React, { useEffect, useState } from 'react';

// Packages
import { Badge, Col, Divider, Flex, Row, Space, Tree, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const TreeTitle = (props) => {
	const { title, updateType, value } = props;
	
	const { t } = useTranslation();
	
	const [badgeValues, setbadgeValues] = useState({ count: t('loadStatusDetails.review.noAction.badge'), color: '#8c8c8c' });

	useEffect(() => {
		if (updateType === 'C') {
			setbadgeValues({ count: 'C', color: '#06c281' });
		}
		else if (updateType === 'U') {
			setbadgeValues({ count: t('loadStatusDetails.review.update.badge'), color: '#1890ff' });
		}
	}, [updateType]);

	return (
		<Space>
			<Badge count={badgeValues.count} color={badgeValues.color} />
			<Typography.Text>{title}</Typography.Text>
			{value && <Typography.Text type='secondary'>-&nbsp;&nbsp;{t('routesTable.headers.locations')}: {value}</Typography.Text>}
		</Space>
	)
};

TreeTitle.propTypes = {
	title: PropTypes.string.isRequired,
	updateType: PropTypes.string.isRequired,
	value: PropTypes.any
}


const LoadFileReview = (props) => {
	const { treeData } = props;

	const { t } = useTranslation();

	const [loadInfo, setLoadInfo] = useState([]);

	const createLoadInfo = (nodes) => {
		return nodes.map((node) => {
			let newNode = {
				...node,
				title: <TreeTitle title={node.title} updateType={node.update} value={node.value} />,
				key: `${node.title}-${Math.floor(Math.random() * 1000000)}`
			};
	
			if (Array.isArray(node.children)) {
				newNode.children = createLoadInfo(node.children);
			}
	
			return newNode;
		});
	}

	useEffect(() => {
		if (treeData.length > 0) {
			const info = createLoadInfo(treeData);
			let newLoadInfoArrays = info.map(objeto => [objeto]);
			setLoadInfo(newLoadInfoArrays);
		}
	}, [treeData]);

	return (
		<div>
			<Divider />
			<Typography.Title className='text-center mt-4 mb-3' level={4}>{t('loadStatusDetails.review.title')}</Typography.Title>

			<Flex justify='flex-end' style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
				<Space size='large'>
					<Space size={6}>
						<Badge count={t('loadStatusDetails.review.noAction.badge')} showZero color='#8c8c8c'/>
						<Typography.Text type='secondary'> - </Typography.Text>
						<Typography.Text type='secondary'>{t('loadStatusDetails.review.noAction.desc')}</Typography.Text>
					</Space>
					<Space size={6}>
						<Badge count='C' color='#06c281'/>
						<Typography.Text type='secondary'> - </Typography.Text>
						<Typography.Text type='secondary'>{t('loadStatusDetails.review.creation.desc')}</Typography.Text>
					</Space>
					<Space size={6}>
						<Badge count={t('loadStatusDetails.review.update.badge')} color='#1890ff'/>
						<Typography.Text type='secondary'> - </Typography.Text>
						<Typography.Text type='secondary'>{t('loadStatusDetails.review.update.desc')}</Typography.Text>
					</Space>
				</Space>
			</Flex>

			<Row justify='center' gutter={16} style={{ borderRadius: '5px', backgroundColor: 'white', padding: '10px' }}>
				{loadInfo.length > 0 &&
					loadInfo.map((node, idx) => (
						<Col
							key={idx}
							style={{ borderRight: idx !== loadInfo.length - 1 ? '1px solid #d9d9d9' : 'none' }}
							lg={6} md={6} sm={24} xs={24}
						>
							<Tree treeData={node} />
						</Col>
					))
				}
			</Row>
		</div>
	);
}

LoadFileReview.propTypes = {
	treeData: PropTypes.array.isRequired
}

export default LoadFileReview;
