import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, Modal, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_cycles, get_modules, get_workspaces } from '../../../redux/actions/organizationActions';
import { self_schema_create, self_schema_update } from '../../../redux/actions/selfSchemasActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { load_scope_options, load_field_type_options } from '../../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';

const SchemasFormModal = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, orgFilters, update, schemaToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	const { self_schema_create_loading, self_schema_update_loading, self_schemas_errors } = useSelector(state => state.self_schemas);
	
	const [form] = Form.useForm();
	const [loadScopeOptions, setloadScopeOptions] = useState([]);
	const [loadFieldTypesOptions, setLoadFieldTypesOptions] = useState([]);

	const LOAD_SCOPES = load_scope_options();
	const LOAD_FIELD_TYPES = load_field_type_options();

	useEffect(() => {
		setloadScopeOptions(create_select_options_from_array(LOAD_SCOPES));
		setLoadFieldTypesOptions(create_select_options_from_array(LOAD_FIELD_TYPES));
	}, []);

	useEffect(() => {
		if (!isEmpty(schemaToUpdate)) {
			form.setFieldsValue({
				name: schemaToUpdate.name,
				description: schemaToUpdate.description,
				type: schemaToUpdate.field_type,
				code: schemaToUpdate.code,
				identifier: schemaToUpdate.identifier,
				primary: schemaToUpdate.primary,
				check: schemaToUpdate.check,
				optional: schemaToUpdate.optional
			});
		}
	}, [schemaToUpdate]);

	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.organization) {
			form.setFieldsValue({ workspace: undefined, module: undefined, cycle: undefined });

			dispatch(get_workspaces({ organization: changedValues.organization }));
			dispatch(get_modules({ organization: changedValues.organization }));
		}

		if (changedValues.workspace) {
			form.setFieldsValue({ module: undefined, cycle: undefined });
		}
		
		if (changedValues.module) {
			form.setFieldsValue({ cycle: undefined });

			if (allValues.workspace) {
				dispatch(get_cycles({ workspace: allValues.workspace, module: changedValues.module }));
			}
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					values = {
						...values,
						app: null,
						organization: values.organization ?? orgFilters.organization,
						workspace: values.workspace ?? orgFilters.workspace,
						module: values.module ?? orgFilters.module
					};

					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(self_schema_create(messageApi, values, handleCancel, filters));
				}
				else {
					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(self_schema_update(messageApi, schemaToUpdate?._id?.$oid, values, handleCancel, filters));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('schemasForm.create.title') : t('schemasForm.edit.title')}
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={[
				<Button key='back' onClick={handleCancel}>
					{t('schemasForm.cancel')}
				</Button>,
				<Button
					key='submit'
					type='primary'
					loading={!update ? self_schema_create_loading : self_schema_update_loading}
					onClick={onOk}
				>
					{!update ? t('schemasForm.create.title').split(' ')[0] : t('schemasForm.edit.title').split(' ')[0]}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 4 }}
				form={form}
				onValuesChange={onValuesChange}
			>
				{self_schemas_errors.hasOwnProperty('self_schema_create') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('schemasForm.create.msg.error')} type='error' showIcon />
					</div>
				}

				{self_schemas_errors.hasOwnProperty('self_schema_update') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('schemasForm.edit.msg.error')} type='error' showIcon />
					</div>
				}

				{!update &&
					<Fragment>
						{orgFilters.organization === '' &&
							<Form.Item
								label={t('filters.organization')}
								name='organization'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.organization'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.organization')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(organizations, 'name')}
								/>
							</Form.Item>
						}
						{orgFilters.workspace === '' &&
							<Form.Item
								label={t('filters.workspace')}
								name='workspace'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.workspace'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.workspace')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(workspaces, 'name')}
								/>
							</Form.Item>
						}
						{orgFilters.module === '' &&
							<Form.Item
								label={t('filters.module')}
								name='module'
								rules={[
									{
										required: true,
										message: t('loadForm.validationMsg.module'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('loadForm.placeholder.module')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(modules, 'name')}
								/>
							</Form.Item>
						}
						<Form.Item
							label={t('schemasForm.inputs.scope')}
							name='scope'
							rules={[
								{
									required: true,
									message: t('schemasForm.validationMsg.scope'),
								}
							]}
						>
							<Select
								showSearch
								placeholder={t('schemasForm.placeholder.scope')}
								optionFilterProp='children'
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={loadScopeOptions}
							/>
						</Form.Item>
					</Fragment>
				}
				<Form.Item
					label={t('routesTable.headers.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.name'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.name')} />
				</Form.Item>
				<Form.Item
					label={t('loadFileInput.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.description'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.description')} />
				</Form.Item>
				<Form.Item
					label={t('schemasForm.inputs.fieldType')}
					name='type'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.fieldType'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={t('schemasForm.placeholder.fieldType')}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={loadFieldTypesOptions}
					/>
				</Form.Item>
				<Form.Item
					label={t('schemasForm.inputs.code')}
					name='code'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.boolean'),
						}
					]}
				>
					<Radio.Group>
						<Radio value={true}>{t('schemasForm.booleanOptions.true')}</Radio>
						<Radio value={false}>{t('schemasForm.booleanOptions.false')}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label={t('schemasForm.inputs.identifier')}
					name='identifier'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.boolean'),
						}
					]}
				>
					<Radio.Group>
						<Radio value={true}>{t('schemasForm.booleanOptions.true')}</Radio>
						<Radio value={false}>{t('schemasForm.booleanOptions.false')}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label={t('schemasForm.inputs.primary')}
					name='primary'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.boolean'),
						}
					]}
				>
					<Radio.Group>
						<Radio value={true}>{t('schemasForm.booleanOptions.true')}</Radio>
						<Radio value={false}>{t('schemasForm.booleanOptions.false')}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label={t('schemasForm.inputs.check')}
					name='check'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.boolean'),
						}
					]}
				>
					<Radio.Group>
						<Radio value={true}>{t('schemasForm.booleanOptions.true')}</Radio>
						<Radio value={false}>{t('schemasForm.booleanOptions.false')}</Radio>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					label={t('schemasForm.inputs.optional')}
					name='optional'
					rules={[
						{
							required: true,
							message: t('schemasForm.validationMsg.boolean'),
						}
					]}
				>
					<Radio.Group>
						<Radio value={true}>{t('schemasForm.booleanOptions.true')}</Radio>
						<Radio value={false}>{t('schemasForm.booleanOptions.false')}</Radio>
					</Radio.Group>
				</Form.Item>
			</Form>
		</Modal>
	)
}

SchemasFormModal.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	schemaToUpdate: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default SchemasFormModal;
