import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Col, Flex, Row, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import RoutesMove from './RoutesMove';

// Actions
import { get_routes } from '../../redux/actions/routesActions';

// Utils
import { get_route_status_desc } from '../../utils/get_status_desc';
import { get_route_color_status } from '../../utils/get_color_status';

const RoutesTable = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { org_filters } = useSelector(state => state.organization);

	const { area_filters } = useSelector(state => state.areas);

	const { routes_loading, routes } = useSelector(state => state.routes);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [routesToMove, setRoutesToMove] = useState([]);
	const [routeInfo, setRouteInfo] = useState({});
	const [routeGroupId, setRouteGroupId] = useState('');
	const [pageSize] = useState(10);

	const rowSelection = {
		/**
		 * @param {any} selectedRowKeys - Selected row key value
		 * @param {object} selectedRows - Selected row data
		 */
		onChange: (selectedRowKeys, selectedRows) => {
			setRoutesToMove(selectedRows);
		},
		getCheckboxProps: (record) => ({
			name: record.name
		})
	};

	const checkRoutesGroup = () => {
		// Get first group id
		const firstGroupId = routesToMove[0].group.$oid;

		// Check if all elements have the same group id
		for (let i = 1; i < routesToMove.length; i++) {
			if (routesToMove[i].group.$oid !== firstGroupId) {
				// Group id is not the same on traversed elements
				return { isSameGroup: false, firstGroupId: firstGroupId };
			}
		}

		return { isSameGroup: true, firstGroupId: firstGroupId };
	}

	const openMoveModal = () => {
		const { isSameGroup, firstGroupId } = checkRoutesGroup();
		
		if (isSameGroup) {
			setRouteGroupId(firstGroupId);
			setRouteInfo(routesToMove[0]);
			setIsModalOpen(true);
		}
		else {
			messageApi.error(t('routesMove.moveError'), 4);
		}
	}

	const columns = [
		{
			title: t('routesTable.headers.name'),
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.code'),
			dataIndex: 'code',
			key: 'code'
		},
		{
			title: t('routesTable.headers.locations'),
			dataIndex: 'locations',
			key: 'locationsCount',
			render: (locations) => locations.length
		},
		{
			title: t('routesTable.headers.status'),
			dataIndex: 'status',
			key: 'status',
			render: (_, record) => (
				<Tag color={get_route_color_status(record?.status)}>{get_route_status_desc(record?.status)}</Tag>
			)
		},
		{
			title: t('routesTable.headers.user'),
			dataIndex: ['user', 'username'],
			key: 'user'
		},
		{
			title: t('routesTable.headers.action'),
			key: 'view',
			render: (_, record) => (
				<Flex gap='small' wrap='wrap'>
					<Button 
						type='primary'
						shape='circle'
						size='large'
						className='success-btn'
						icon={<span className='material-symbols-outlined'>store</span>}
						onClick={() => navigate(`/route/${record._id.$oid}/locations`)}
					/>
				</Flex>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pageSize;

		const filesFilters = { ...org_filters, ...area_filters, skip: skip, limit: pageSize };
		dispatch(get_routes(filesFilters));
	};

	return (
		<div style={{ marginTop: '3rem' }}>
			<RoutesMove
				messageApi={messageApi}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				routeInfo={routeInfo}
				routesToMove={routesToMove}
				routeGroupId={routeGroupId}
			/>

			<Row justify='end' style={{ textAlign: 'end', marginBottom: '0.5rem' }}>
				<Col span={24}>
					<Tooltip title={t('routesMove.button')}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							disabled={routesToMove.length === 0}
							icon={<span className='material-symbols-outlined'>move_group</span>}
							onClick={() => openMoveModal()}
						/>
					</Tooltip>
				</Col>
			</Row>
			<Spin spinning={routes_loading} tip={t('orgRoutes.loading')} size='large'>
				<Table
					rowSelection={{ ...rowSelection }}
					columns={columns}
					dataSource={routes.routes}
					onChange={handleTableChange}
					pagination={{
						pageSize: pageSize, 
						total: routes.count,
						showSizeChanger: false,
						showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
					}}
				/>
			</Spin>
		</div>
	);
}

RoutesTable.propTypes = {
	messageApi: PropTypes.object.isRequired
};

export default RoutesTable;
