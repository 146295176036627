import {
	AREAS_GET,
	AREAS_ERROR,
	REGIONS_GET,
	REGIONS_ERROR,
	ZONES_GET,
	ZONES_ERROR,
	DEPOTS_GET,
	DEPOTS_ERROR,
	GROUPS_GET,
	GROUPS_ERROR,
	GROUP_INFO_GET,
	GROUP_INFO_ERROR,
	AREA_FILTERS_SET
} from '../types';

const initialState = {
	areas: [],
	regions: { count: 0, regions: [] },
	zones: { count: 0, zones: [] },
	depots: { count: 0, depots: [] },
	groups: { count: 0, groups: [] },
	group_info: {},
	area_filters: {},
	areas_errors: {}
}

export default function areasReducer(state = initialState, action){
	switch (action.type){
		case AREAS_GET:
			return {
				...state,
				areas: action.payload
			}
		case REGIONS_GET:
			return {
				...state,
				regions: action.payload
			}
		case ZONES_GET:
			return {
				...state,
				zones: action.payload
			}
		case DEPOTS_GET:
			return {
				...state,
				depots: action.payload
			}
		case GROUPS_GET:
			return {
				...state,
				groups: action.payload
			}
		case GROUP_INFO_GET:
			return {
				...state,
				group_info: action.payload
			}
		case AREA_FILTERS_SET:
			return {
				...state,
				area_filters: action.payload
			}
		case AREAS_ERROR:
		case REGIONS_ERROR:
		case ZONES_ERROR:
		case DEPOTS_ERROR:
		case GROUPS_ERROR:
		case GROUP_INFO_ERROR:
			return {
				...state,
				areas_errors: {
					...state.areas_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
