import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../../filters/OrgFilters';
import ConfigFormModal from './ConfigFormModal';
import ConfigsTable from './ConfigsTable';

// Actions
import { get_all_self_configurations } from '../../../redux/actions/selfConfigActions';

const Configurations = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [update, setUpdate] = useState(false);
	const [configToUpdate, setConfigToUpdate] = useState({});
	const [pageSize] = useState(10);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '' && orgFilters.module !== '') {
			const filters = { ...orgFilters, skip: 0, limit: 10 };
			dispatch(get_all_self_configurations(filters));
		}
	}, [orgFilters]);

	const createConfig = () => {
		setIsModalOpen(true);
		setUpdate(false);
		setConfigToUpdate({});
	}

	return (
		<Row justify='center'>
			<Col lg={24} md={24} sm={24} xs={24}>
				<ConfigFormModal
					messageApi={messageApi}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					orgFilters={orgFilters}
					update={update}
					configToUpdate={configToUpdate}
					pageSize={pageSize}
					skip={skip}
				/>

				<Row justify='center'>
					<Col lg={8} md={8} sm={24} xs={24}></Col>
					<Col lg={8} md={8} sm={24} xs={24}>
						<Typography.Title className='text-center' level={2}>{t('config.title')}</Typography.Title>
					</Col>
					<Col style={{ textAlign: 'end' }} lg={8} md={8} sm={24} xs={24}>
						<Button
							type='primary'
							size='large'
							onClick={() => createConfig()}
						>
							{t('configForm.create.title').split(' ')[0]}
						</Button>
					</Col>
				</Row>

				<Row justify='center'>
					<Col lg={17} md={17} sm={24} xs={24}>
						<OrgFilters
							orgFilters={orgFilters}
							setOrgFilters={setOrgFilters}
							displayFilters={{ organization: true, workspace: true, module: true }}
						/>
					</Col>
				</Row>

				<ConfigsTable
					messageApi={messageApi}
					orgFilters={orgFilters}
					setIsModalOpen={setIsModalOpen}
					setUpdate={setUpdate}
					setConfigToUpdate={setConfigToUpdate}
					pageSize={pageSize}
					skip={skip}
					setSkip={setSkip}
				/>
			</Col>
		</Row>
	);
}

Configurations.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Configurations;
