/**
 * @param {Array} locationsArray - Array of locations
 * @returns Array of strings with locations ids
 */
const create_locations_ids_array = (locationsArray) => {
	return locationsArray.map((location) => location.$oid || location._id.$oid);
}

/**
 * @param {Array} routeLocations - Array of locations of the route
 * @param {Array} locationsToAdd - Array of locations to add to the route
 * @returns Array of strings with locations ids
 */
const add_location_to_route = (routeLocations, locationsToAdd) => {
	const routeLocationsIds = create_locations_ids_array(routeLocations);
	
	const locationsToAddIds = create_locations_ids_array(locationsToAdd);

	const locationsIds = routeLocationsIds.concat(locationsToAddIds);

	return locationsIds;
}

/**
 * @param {Array} routeLocations - Array of locations of the route
 * @param {Array} locationsToRemove - Array of locations to remove from the route
 * @returns Array of strings with locations ids
 */
const remove_location_from_route = (routeLocations, locationsToRemove) => {
	const newLocationsArray = routeLocations.filter((location) => !locationsToRemove.some(locRm => locRm._id.$oid === location._id.$oid));
	
	const locationsIds = create_locations_ids_array(newLocationsArray);

	return locationsIds;
}

export { add_location_to_route, remove_location_from_route };
