import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Modal, Row, Select, Space, Steps, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_group_info, get_groups } from '../../redux/actions/areasActions';
import { move_routes } from '../../redux/actions/routesActions';

const RoutesMove = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, routeInfo, routesToMove, routeGroupId } = props;
	
	const { t } = useTranslation();
	
	const dispatch = useDispatch();
	
	const { org_filters } = useSelector(state => state.organization);

	const { groups, group_info, area_filters } = useSelector(state => state.areas);

	const [step, setStep] = useState(0);
	const [destinationGroup, setDestinationGroup] = useState({ id: '', name: '', info: {} });
	const [displayGroupsAlert, setDisplayGroupsAlert] = useState(false);;

	const handleCancel = () => {
		setIsModalOpen(false);
		setStep(0);
	}

	useEffect(() => {
		if (routeGroupId.length > 0) {
			dispatch(get_groups({
				organization: routeInfo.organization.$oid,
				workspace: routeInfo.workspace.$oid,
				region: routeInfo.region.$oid,
				zone: routeInfo.zone.$oid,
				depot: routeInfo.depot.$oid
			}, routeGroupId));

			dispatch(get_group_info(routeGroupId));
		}
	}, [routeGroupId]);

	useEffect(() => {
		if (isModalOpen) {
			if (groups.groups.length === 0) {
				setDisplayGroupsAlert(true);
			}
		}
	}, [isModalOpen, groups]);

	useEffect(() => {
		if (destinationGroup.id !== '') {
			const groupFound = groups.groups.find((group) => group._id.$oid === destinationGroup.id);

			if (groupFound) {
				setDestinationGroup({ ...destinationGroup, info: groupFound });
			}
		}
	}, [destinationGroup.id]);

	const confirmMove = async () => {
		if (destinationGroup.id.length === 0) {
			messageApi.error(t('routesMove.update.groupsError'), 4);
			return;
		}

		dispatch(move_routes(messageApi, handleCancel, routesToMove, destinationGroup, { ...org_filters, ...area_filters }));
	}

	return (
		<Modal
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={null}
		>
			<Steps
				current={step}
				items={[
					{
						title: t('filters.group'),
						description: t('routesMove.steps.first.desc')
					},
					{
						title: t('locationsMove.steps.second.title'),
						description: t('locations.finishChanges')
					}
				]}
			/>

			{step === 0 && (
				<Form
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 24 }}
					layout='horizontal'
					size='default'
					style={{ maxWidth: 600, marginTop: '2rem' }}
				>
					{displayGroupsAlert &&
						<div style={{ paddingLeft: '42px', marginBottom: '2rem' }}>
							<Alert message={t('routesMove.moveGroup')} type='info' showIcon />
						</div>
					}
					<Form.Item label={t('filters.group')}>
						<Select
							style={{ width: '100%' }}
							options={groups.groups.map(group => ({ value: group._id.$oid, label: group.name }))}
							onChange={(value, option) => setDestinationGroup({ id: value, name: option.label })}
						/>
					</Form.Item>
					{destinationGroup.id !== '' && (
						<Form.Item
							wrapperCol={{ offset: 11, span: 16 }}
						>
							<Button type='primary' htmlType='submit' onClick={() => setStep(step + 1)}>
								{t('locationsMove.steps.continue')}
							</Button>
						</Form.Item>
					)}
				</Form>
			)}
			{step === 1 && (
				<Row justify='center' style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '1.5rem' }}>
					<Space direction='vertical'>
						<Typography.Title level={5}>{t('routesMove.steps.second.content.title')}</Typography.Title>

						<Space direction='vertical' style={{ marginBottom: '1rem' }}>
							{routesToMove.map(route => (
								<Typography.Text key={route._id.$oid}>{route.code}</Typography.Text>
							))}
						</Space>

						<Typography.Text>
							<strong>{t('routesMove.steps.second.content.from')}</strong> {group_info?.name}
							<ArrowRightOutlined style={{ color: '#3e619b', marginLeft: '10px', marginRight: '10px' }} />
							<strong>{t('routesMove.steps.second.content.to')}</strong> {destinationGroup.name}
						</Typography.Text>

						<Space direction='horizontal' size={100} style={{ marginTop: '2rem' }}>
							<Button onClick={() => setStep(step - 1)}>{t('locationsMove.steps.second.content.back')}</Button>
							<Button type='primary' onClick={() => confirmMove()}>{t('locationsMove.steps.second.content.confirm')}</Button>
						</Space>
					</Space>
				</Row>
			)}
		</Modal>
	)
}

RoutesMove.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	routeInfo: PropTypes.object.isRequired,
	routesToMove: PropTypes.array.isRequired,
	routeGroupId: PropTypes.string.isRequired
}

export default RoutesMove;
