import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Input, Popconfirm, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { FilterFilled, QuestionCircleFilled, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_self_schemas, self_schema_delete } from '../../../redux/actions/selfSchemasActions';

// Utils
import { get_load_scope_desc, get_load_status_desc, get_load_field_type_desc, get_load_value_desc } from '../../../utils/get_status_desc';
import { get_load_status_color } from '../../../utils/get_color_status';

const SchemasTable = (props) => {
	const { messageApi, orgFilters, setIsModalOpen, setUpdate, setSchemaToUpdate, pageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { all_self_schemas_loading, all_self_schemas, self_schema_delete_loading } = useSelector(state => state.self_schemas);

	const searchInput = useRef(null);
	const [searchedColumn, setSearchedColumn] = useState('');
	const [searchText, setSearchText] = useState('');
	
	const parseData = (value) => {
		let text = '#N/A';

		if (typeof value === 'boolean') {
			if (value) {
				text = t('schemasForm.booleanOptions.true');
			}
			else {
				text = t('schemasForm.booleanOptions.false');
			}
		}

		return text;
	}

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		setSchemaToUpdate(record);
	}

	const deleteSelfSchema = (record) => {
		const filters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(self_schema_delete(messageApi, record._id.$oid, filters));
	}

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
		setSearchedColumn('');
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					style={{ marginBottom: 8, display: 'block' }}
					ref={searchInput}
					placeholder={t('form.placeholders.searchIngredient')}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => {
						handleSearch(selectedKeys, confirm, dataIndex);
						close();
					}}
				/>
				<Space>
					<Button
						style={{ width: 95 }}
						type='primary'
						size='small'
						icon={<SearchOutlined />}
						onClick={() => {
							handleSearch(selectedKeys, confirm, dataIndex);
							close();
						}}
					>
						{t('locations.table.search.search')}
					</Button>
					<Button
						style={{ width: 110 }}
						size='small'
						icon={<SyncOutlined />}
						onClick={() => {
							clearFilters && handleReset(clearFilters);
							confirm();
							close();
						}}
					>
						{t('locations.table.search.reset')}
					</Button>
				</Space>
			</div>
		),
		filterIcon: () => (
			<SearchOutlined
				style={{
					color: searchText.length > 0 ? '#FFFFFF' : '#818D9C',
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) => text
	});

	const columns = [
		...(orgFilters.organization === ''
			? [
					{
						title: t('filters.organization'),
						dataIndex: ['organization', '$oid'],
						key: 'organization'
					}
				]
			: []
		),
		...(orgFilters.workspace === ''
			? [
					{
						title: t('filters.workspace'),
						dataIndex: ['workspace', '$oid'],
						key: 'workspace'
					}
				]
			: []
		),
		...(orgFilters.module === ''
			? [
					{
						title: t('filters.module'),
						dataIndex: ['module', '$oid'],
						key: 'module'
					}
				]
			: []
		),
		{
			title: t('routesTable.headers.name'),
			dataIndex: 'name',
			key: 'name',
			...getColumnSearchProps('name'),
			sorter: (a, b) => a.name.localeCompare(b.name),
			showSorterTooltip: false,
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		// {
		// 	title: t('loadFileInput.description'),
		// 	dataIndex: 'description',
		// 	key: 'description'
		// },
		{
			title: t('schemasForm.inputs.scope'),
			dataIndex: 'scope',
			key: 'scope',
			filters: [
				{
					text: t('statusDesc.loadScope.global'),
					value: 1,
				},
				{
					text: t('statusDesc.loadScope.organization'),
					value: 2,
				},
				{
					text: t('statusDesc.loadScope.workspace'),
					value: 3,
				},
				{
					text: t('statusDesc.loadScope.module'),
					value: 4,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.scope === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{get_load_scope_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.status'),
			dataIndex: 'status',
			key: 'status',
			filters: [
				{
					text: t('statusDesc.load.created'),
					value: 1,
				},
				{
					text: t('statusDesc.load.review'),
					value: 2,
				},
				{
					text: t('statusDesc.load.waiting'),
					value: 3,
				},
				{
					text: t('statusDesc.load.processing'),
					value: 4,
				},
				{
					text: t('statusDesc.load.good'),
					value: 5,
				},
				{
					text: t('statusDesc.load.incomplete'),
					value: 6,
				},
				{
					text: t('statusDesc.load.bad'),
					value: 7,
				},
				{
					text: t('statusDesc.load.removed'),
					value: 8,
				},
				{
					text: t('statusDesc.load.error'),
					value: 9,
				},
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.status === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (_, record) => (
				<Tag color={get_load_status_color(record?.status)}>{get_load_status_desc(record?.status)}</Tag>
			)
		},
		{
			title: t('schemasForm.inputs.fieldType'),
			dataIndex: 'field_type',
			key: 'field_type',
			filters: [
				{
					text: t('statusDesc.loadFieldType.int'),
					value: 1,
				},
				{
					text: t('statusDesc.loadFieldType.float'),
					value: 2,
				},
				{
					text: t('statusDesc.loadFieldType.string'),
					value: 3,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.field_type === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{get_load_field_type_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('schemasForm.inputs.code'),
			dataIndex: 'code',
			key: 'code',
			filters: [
				{
					text: t('schemasForm.booleanOptions.true'),
					value: true,
				},
				{
					text: t('schemasForm.booleanOptions.false'),
					value: false,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.code === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{parseData(text)}</Typography.Text>
			)
		},
		{
			title: t('schemasForm.inputs.identifier'),
			dataIndex: 'identifier',
			key: 'identifier',
			filters: [
				{
					text: t('schemasForm.booleanOptions.true'),
					value: true,
				},
				{
					text: t('schemasForm.booleanOptions.false'),
					value: false,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.identifier === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{parseData(text)}</Typography.Text>
			)
		},
		{
			title: t('schemasForm.inputs.primary'),
			dataIndex: 'primary',
			key: 'primary',
			filters: [
				{
					text: t('schemasForm.booleanOptions.true'),
					value: true,
				},
				{
					text: t('schemasForm.booleanOptions.false'),
					value: false,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.primary === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{parseData(text)}</Typography.Text>
			)
		},
		{
			title: t('schemasForm.inputs.check'),
			dataIndex: 'check',
			key: 'check',
			filters: [
				{
					text: t('schemasForm.booleanOptions.true'),
					value: true,
				},
				{
					text: t('schemasForm.booleanOptions.false'),
					value: false,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.check === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{parseData(text)}</Typography.Text>
			)
		},
		{
			title: t('schemasForm.inputs.optional'),
			dataIndex: 'optional',
			key: 'optional',
			filters: [
				{
					text: t('schemasForm.booleanOptions.true'),
					value: true,
				},
				{
					text: t('schemasForm.booleanOptions.false'),
					value: false,
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.optional === value,
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{parseData(text)}</Typography.Text>
			)
		},
		{
			title: t('errorForm.inputs.value'),
			dataIndex: 'value',
			key: 'value',
			filters: [
				{
					text: 'None',
					value: 0,
				},
				{
					text: 'Other Values',
					value: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				}
			],
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => {
				if (Array.isArray(value)) {
					return value.includes(record.value);
				}
				return record.value === value;
			},
			filterIcon: (filtered) => (
				<FilterFilled style={{ color: filtered ? 'white' : '#818D9C' }} />
			),
			render: (text) => (
				<Typography.Text>{get_load_value_desc(text)}</Typography.Text>
			)
		},	
		{
			title: t('loadStatus.statusTable.date'),
			dataIndex: ['date', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(_ls.i18nextLng, { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.action'),
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('schemasForm.edit.title').split(' ')[0]}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<i className='bi bi-pencil-fill'></i>}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('schemasForm.delete.popconfirm.title')}
						description={t('schemasForm.delete.popconfirm.description')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteSelfSchema(record)}
						okButtonProps={{
							loading: self_schema_delete_loading
						}}
						okText={t('schemasForm.delete.popconfirm.confirm')}
						cancelText={t('schemasForm.delete.popconfirm.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<span className='material-symbols-outlined'>delete</span>}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pageSize;
		setSkip(skip);

		const schemassFilters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(get_all_self_schemas(schemassFilters));
	};

	return (
		<div className='mt-5'>
			<Spin spinning={all_self_schemas_loading} tip={t('schemas.loading')} size='large'>
				<Table
					columns={columns}
					dataSource={all_self_schemas.schemas}
					// onChange={handleTableChange}
					// pagination={{
					// 	pageSize: pageSize,
					// 	total: all_self_schemas.count,
					// 	showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
					// }}
					pagination={false}
				/>
			</Spin>
		</div>
	)
}

SchemasTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	orgFilters: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setSchemaToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default SchemasTable;
