import {
	CLEAR_ALL_CYCLES_DATA,
	CLEAR_ALL_CYCLES_ERRORS,
	CLEAR_CYCLES_ERROR,
	SET_CYCLE_ID,
	CYCLE_CREATE_LOADING,
	CYCLE_CREATE_ERROR,
	ALL_CYCLES_LOADING,
	ALL_CYCLES_GET,
	ALL_CYCLES_ERROR,
	CYCLE_UPDATE_LOADING,
	CYCLE_UPDATE_ERROR,
	CYCLE_DELETE_LOADING
} from '../types';

const initialState = {
	cycle_create_loading: false,
	cycle_id: null,
	all_cycles_loading: false,
	all_cycles: { count: 0, cycles: [] },
	cycle_update_loading: false,
	cycle_delete_loading: false,
	cycles_errors: {}
}

export default function cyclesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_CYCLES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_CYCLES_ERRORS:
			return {
				...state,
				cycles_errors: {}
			}
		case CLEAR_CYCLES_ERROR:
			return {
				...state,
				cycles_errors: Object.keys(state.cycles_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.cycles_errors[key];
					}
					return acc;
				}, {})
			}
		case SET_CYCLE_ID:
			return {
				...state,
				cycle_id: action.payload
			}
		case CYCLE_CREATE_LOADING:
			return {
				...state,
				cycle_create_loading: action.payload
			}
		case ALL_CYCLES_LOADING:
			return {
				...state,
				all_cycles_loading: action.payload
			}
		case ALL_CYCLES_GET:
			return {
				...state,
				all_cycles: {
					count: action.payload.count,
					cycles: action.payload.cycles
				}
			}
		case CYCLE_UPDATE_LOADING:
			return {
				...state,
				cycle_update_loading: action.payload
			}
		case CYCLE_DELETE_LOADING:
			return {
				...state,
				cycle_delete_loading: action.payload
			}
		case CYCLE_CREATE_ERROR:
		case ALL_CYCLES_ERROR:
		case CYCLE_UPDATE_ERROR:
			return {
				...state,
				cycles_errors: {
					...state.cycles_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
