import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import SelectButton from '../utils/SelectButton';

// Actions
import { get_depots, get_groups, get_regions, get_zones, set_area_filters } from '../../redux/actions/areasActions';
import { get_routes } from '../../redux/actions/routesActions';

const AreaFilters = (props) => {
	const { orgFilters, areaFilters, setAreaFilters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { regions, zones, depots, groups } = useSelector(state => state.areas);

	const onChangeAreaFilters = (e, name) => {
		let filters = {...areaFilters};
		let keys = Object.keys(filters);
		
		if (e === null){
			let idx = keys.indexOf(name);
			for (let index = idx; index < keys.length; index++) {
				const key = keys[index];
				filters[key] = '';
				localStorage.setItem(key, '');
			}
		}
		else {
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setAreaFilters(filters);
		dispatch(set_area_filters(filters));
	}

	const createFilters = (orgFilters, areaFilters) => {
		let filters = { organization: orgFilters.organization, workspace: orgFilters.workspace };

		for (const key in areaFilters) {
			if (areaFilters[key] !== '') {
				filters[key] = areaFilters[key];
			}
		}

		return filters;
	}

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '') {
			const filters = createFilters(orgFilters, areaFilters);

			dispatch(get_regions(filters));
			dispatch(get_zones(filters));
			dispatch(get_depots(filters));
			dispatch(get_groups(filters));
			dispatch(get_routes({ ...filters, module: orgFilters.module, skip: 0, limit: 10 }));
		}
	}, [orgFilters, areaFilters]);

	return (
		<Row justify='center' gutter={[16, 16]}>
			<Col className='gutter-row' sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
				<Row justify='center'>
					<Typography.Text>{t('filters.region')}</Typography.Text>
				</Row>
				<SelectButton
					options={regions.regions}
					name='region'
					value={areaFilters.region === '' ? null : areaFilters.region}
					onChange={onChangeAreaFilters}
					disable={false}
					areaFilters={true}
				/>
			</Col>
			<Col className='gutter-row' sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
				<Row justify='center'>
					<Typography.Text>{t('filters.zone')}</Typography.Text>
				</Row>
				<SelectButton
					options={zones.zones}
					name='zone'
					value={areaFilters.zone === '' ? null : areaFilters.zone}
					onChange={onChangeAreaFilters}
					disable={false}
					areaFilters={true}
				/>
			</Col>
			<Col className='gutter-row' sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
				<Row justify='center'>
					<Typography.Text>{t('filters.depot')}</Typography.Text>
				</Row>
				<SelectButton
					options={depots.depots}
					name='depot'
					value={areaFilters.depot === '' ? null : areaFilters.depot}
					onChange={onChangeAreaFilters}
					disable={false}
					areaFilters={true}
				/>
			</Col>
			<Col className='gutter-row' sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }}>
				<Row justify='center'>
					<Typography.Text>{t('filters.group')}</Typography.Text>
				</Row>
				<SelectButton
					options={groups.groups}
					name='group'
					value={areaFilters.group === '' ? null : areaFilters.group}
					onChange={onChangeAreaFilters}
					disable={false}
					areaFilters={true}
				/>
			</Col>
		</Row>
	);
}

AreaFilters.propTypes = {
	orgFilters: PropTypes.object.isRequired,
	areaFilters: PropTypes.object.isRequired,
	setAreaFilters: PropTypes.func.isRequired
};

export default AreaFilters;
