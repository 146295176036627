export const ENGLISH_TRANSLATIONS = {
	// MAIN COMPONENTS
	navbar: {
		options: {
			load: {
				title: 'Load',
				loads: 'Loads',
				load: 'Upload Files',
				status: 'Load Status'
			}
		},
		menu: {
			account: 'Account',
			return: 'Return to menu',
			logOut: 'Log Out'
		}
	},
	landing: {
		instruction: 'Use this dashboard to be able to review the files that will be uploaded to the system.',
		continue: 'Continue'
	},
	
	// FILTERS
	filters: {
		organization: 'Organization',
		workspace: 'Workspace',
		module: 'Module',
		submodule: 'Submodule',
		cycle: 'Cycle',
		areas: 'Areas',
		region: 'Region',
		zone: 'Zone',
		depot: 'Depot',
		group: 'Group',
		route: 'Route'
	},

	// LOAD FILE
	loadFile: {
		load: 'Load File',
		reviewBtn: 'Upload',
		msg: {
			loading: 'Loading file...',
			success: 'File loaded successfully!',
			error: 'Error loading file, check the information and try again.'
		}
	},
	loadTypeOptions: {
		restructure: 'Restructure',
		coordinates: 'Coordinates',
		cycle: 'Initial',
		single: 'Single'
	},
	loadFileInput: {
		loadType: 'Load type',
		description: 'Description',
		text: 'Click or drag files to this area to upload',
		hint: 'Please make sure that the file you attach is of type csv.',
		error: 'Only csv files allowed.'
	},
	loadForm: {
		create: {
			title: 'Create Load',
			msg: {
				success: 'Load created successfully!',
				error: 'Error creating load, please try again.'
			}
		},
		edit: {
			title: 'Edit Load',
			msg: {
				success: 'Load edited successfully!',
				error: 'Error editing the load, please try again.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Delete Load',
				description: 'Are you sure you want to delete this load?',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			msg: {
				success: 'Load deleted successfully!',
				error: 'Error deleting the load, please try again.'
			}
		},
		placeholder: {
			organization: 'Select an organization.',
			workspace: 'Select a workspace.',
			module: 'Select a module.',
			cycle: 'Select a cycle.',
			type: 'Select a type.',
			load: 'Select a load.',
			name: 'Input a name.',
			description: 'Input a description.'
		},
		validationMsg: {
			organization: 'Please select an organization.',
			workspace: 'Please select a workspace.',
			module: 'Please select a module.',
			cycle: 'Please select a cycle.',
			type: 'Please select a type.',
			name: 'Please input a name.',
			description: 'Please input a description.'
		},
		cancel: 'Cancel'
	},

	// LOAD STATUS
	loadStatus: {
		statusTable: {
			loading: 'Loading...',
			date: 'Date',
			actionButtons: {
				details: 'View Details',
				confirm: 'Confirm',
				discard: 'Discard'
			}
		}
	},
	loadStatusDetails: {
		title: 'File Information',
		card: {
			source: 'Source',
		},
		download: {
			button: 'Download',
			validationBtn: 'Validation',
			error: 'Error downloading file, please try again.'
		},
		check: {
			alert: {
				msg: 'Validation process underway',
				desc: 'Currently, the system is validating this file. Please come back later to access the information that will be uploaded.'
			}
		},
		review: {
			title: 'Information Summary',
			noAction: {
				badge: 'N',
				desc: 'No Action'
			},
			creation: {
				desc: 'Creation'
			},
			update: {
				badge: 'U',
				desc: 'Update'
			}
		},
		confirm: {
			msg: {
				success: 'The file has been confirmed for uploading!',
				error: 'Error in confirming the file for uploading!'
			}
		},
		discard: {
			msg: {
				success: 'The file has been discarded for uploading!',
				error: 'Error in discarding the file for uploading!'
			}
		}
	},
	
	// CYCLE INFO
	cycle: {
		title: 'Cycles',
		loading: 'Loading cycles...',
		type: 'Type',
		creationDate: 'Creation Date',
		selectStart: 'Start',
		selectEnd: ' End',
		start: 'Start of cycle:',
		end: ' End of cycle:',
		workingDays: 'Working days:',
		currentDays: 'Days elapsed:',
		remainingDays: 'Remaining days:',
		routeCycle: {
			msg: {
				error: 'Error loading route cycle, please try again.'
			}
		},
		create: {
			title: 'Create Cycle',
			loading: 'Creating cycle...',
			success: 'Cycle created successfully!',
			error: 'Error creating cycle, please try again.'
		},
		edit: {
			title: 'Edit Cycle',
			loading: 'Editing cycle...',
			success: 'Cycle edited successfully!',
			error: 'Error editing cycle, please try again.'
		},
		endAction: {
			popconfirm: {
				title: 'End Cycle',
				question: 'Are you sure you want to end this cycle?',
				description: 'This action will end the cycle and the routes will no longer be available for the users.',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			msg: {
				loading: 'Ending cycle...',
				success: 'Cycle ended successfully!',
				error: 'Error ending cycle, please try again.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Delete Cycle',
				description: 'Are you sure you want to delete this cycle?',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			msg: {
				loading: 'Deleting cycle...',
				success: 'Cycle deleted successfully!',
				error: 'Error deleting cycle, please try again.'
			}
		},
		placeholder: {
			reference: 'Select an option.',
		},
		validationMsg: {
			reference: 'Please select an option.',
			date: 'Please select a date.',
		}
	},

	// ORGANIZATION ROUTES
	orgRoutes: {
		loading: 'Loading routes...',
		title: 'Routes'
	},
	routesTable: {
		headers: {
			name: 'Name',
			code: 'Code',
			locations: '# Locations',
			status: 'Status',
			module: 'Module',
			user: 'User',
			action: 'Action'
		}
	},
	routeUpdates: {
		msg: {
			loading: 'Loading route...',
			success: 'Route loaded successfully!',
			error: 'Error loading route!'
		}
	},
	routesMove: {
		button: 'Move routes',
		moveError: 'The routes are not from the same group, check and try again.',
		moveGroup: 'There are no available groups to move these routes to. Please close this form.',
		steps: {
			first: {
				desc:'Select the destination group'
			},
			second: {
				content: {
					title: 'Are you sure you want to move these routes?',
					from: 'From group:',
					to: 'To group:'
				}
			}
		},
		update: {
			groupsError: 'No group information found, please try again.',
			loading: 'Moving routes...',
			success: 'Route(s) moved successfully!',
			error: 'Error moving routes'
		}
	},

	// LOCATIONS
	locations: {
		loading: 'Loading locations...',
		table: {
			search: {
				search: 'Search',
				reset: 'Reset'
			}
		},
		finishChanges: 'Save Changes',
		move: 'Move locations'
	},
	locationsUpdates: {
		msg: {
			loading: 'Updating location info...',
			success: 'Updated location info successfully!',
			error: 'Error updating location info!'
		}
	},
	locationsMove: {
		steps: {
			first: {
				desc:'Select the destination route',
			},
			second: {
				title: 'Done',
				content: {
					title: 'Are you sure you want to move these locations?',
					from: 'From:',
					to: 'To:',
					back: 'Back',
					confirm: 'Confirm'
				}
			},
			continue: 'Continue'
		},
		update: {
			routesError: 'No route information found, please try again.',
			loading: 'Updating locations of the route',
			success: 'updated successfully!',
			error: 'Error updating route'
		}
	},

	// ERRORS
	errors: {
		title: 'Errors',
		loading: 'Loading errors...'
	},
	errorForm: {
		inputs: {
			alias: 'Alias',
			value: 'Value'
		},
		create: {
			title: 'Create Error',
			msg: {
				success: 'Error created successfully!',
				error: 'Failed to create error, please try again.'
			}
		},
		edit: {
			title: 'Edit Error',
			msg: {
				success: 'Error edited successfully!',
				error: 'Failed to edit error, please try again.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Delete Error',
				description: 'Are you sure you want to delete this error?',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			msg: {
				success: 'Error deleted successfully!',
				error: 'Failed to delete error, please try again.'
			}
		},
		placeholder: {
			organization: 'Select an organization.',
			alias: 'Enter an alias.',
			name: 'Enter a name.',
			description: 'Enter a description.',
			value: 'Enter an error value.'
		},
		validationMsg: {
			organization: 'Please select an organization.',
			alias: 'Please enter an alias.',
			name: 'Please enter a name.',
			description: 'Please enter a description.',
			value: {
				required: 'Please enter a valid error value.',
				min: 'The value must be greater than 0.'
			}
		},
		cancel: 'Cancel'
	},

	// SCHEMAS
	schemas: {
		title: 'Schemas',
		loading: 'Loading schemas...'
	},
	schemasForm: {
		inputs: {
			scope: 'Scope',
			fieldType: 'Field Type',
			code: 'Code',
			identifier: 'Identifier',
			primary: 'Primary',
			check: 'Check',
			optional: 'Optional'
		},
		booleanOptions: {
			true: 'True',
			false: 'False'
		},
		create: {
			title: 'Create Schema',
			msg: {
				success: 'Schema created successfully!',
				error: 'Failed to create schema, please try again.'
			}
		},
		edit: {
			title: 'Edit Schema',
			msg: {
				success: 'Schema edited successfully!',
				error: 'Failed to edit schema, please try again.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Delete Schema',
				description: 'Are you sure you want to delete this schema?',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			msg: {
				success: 'Schema deleted successfully!',
				error: 'Failed to delete schema, please try again.'
			}
		},
		placeholder: {
			scope: 'Select a scope.',
			fieldType: 'Select a field type.'
		},
		validationMsg: {
			scope: 'Please select a scope.',
			fieldType: 'Please select a field type.',
			boolean: 'Please select an option.'
		},
		cancel: 'Cancel'
	},

	// CONFIGURATIONS
	config: {
		title: 'Configurations',
		loading: 'Loading configurations...'
	},
	configForm: {
		inputs: {
			bq_project: 'BQ Project',
			bq_table: 'BQ Table',
			bq_table_prefix: 'BQ Table Prefix'
		},
		create: {
			title: 'Create Configuration',
			msg: {
				success: 'Configuration created successfully!',
				error: 'Failed to create configuration, please try again.'
			}
		},
		edit: {
			title: 'Edit Configuration',
			msg: {
				success: 'Configuration edited successfully!',
				error: 'Failed to edit configuration, please try again.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Delete Configuration',
				description: 'Are you sure you want to delete this configuration?',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			msg: {
				success: 'Configuration deleted successfully!',
				error: 'Failed to delete configuration, please try again.'
			}
		},
		placeholder: {
			bq_project: 'Enter a BQ Project.',
			bq_table: 'Enter a BQ Table.',
			bq_table_prefix: 'Enter a BQ Table Prefix.'
		},
		validationMsg: {
			bq_project: 'Please enter a BQ Project.',
			bq_table: 'Please enter a BQ Table.',
			bq_table_prefix: 'Please enter a BQ Table Prefix.'
		},
		cancel: 'Cancel'
	},

	// UTILS COMPONENTS
	pagination: {
		rows: 'Rows per page:',
		of: 'of'
	},
	copyableText: {
		copy: 'Copy',
		copied: 'Copied',
		copyId: 'Copy ID',
		copiedId: 'ID Copied'
	},

	// STATUS DESCRIPTIONS
	statusDesc: {
		cycle: {
			none: 'None',
			created: 'Created',
			starting: 'Starting',
			available: 'Available',
			waiting: 'Waiting',
			completed: 'Completed',
			removed: 'Removed'
		},
		route: {
			none: 'None',
			created: 'Created',
			available: 'Available',
			disabled: 'Disabled',
			removed: 'Removed'
		},
		load: {
			none: 'None',
			created: 'Created',
			review: 'In Review',
			waiting: 'Waiting',
			processing: 'Processing',
			good: 'Good',
			incomplete: 'Incomplete',
			bad: 'Bad',
			removed: 'Removed',
			error: 'Error'			
		},
		loadSource: {
			none: 'None',
			cloud: 'Cloud',
			local: 'Local',
			service: 'Service',
			other: 'Other'
		},
		loadScope: {
			none: 'None',
			global: 'Global',
			organization: 'Organization',
			workspace: 'Workspace',
			module: 'Module'
		},
		loadFieldType: {
			none: 'None',
			int: 'Integer',
			float: 'Float',
			string: 'String'
		},
		areaType: {
			locations: 'Locations',
		},
		cycleScope: {
			complete: 'Complete',
			selected: 'Selected'
		}
	}
};
