import {
	CLEAR_SELF_SCHEMAS_ERRORS,
	SELF_SCHEMA_CREATE_LOADING,
	SELF_SCHEMA_CREATE_ERROR,
	ALL_SELF_SCHEMAS_LOADING,
	ALL_SELF_SCHEMAS_GET,
	ALL_SELF_SCHEMAS_ERROR,
	SELF_SCHEMA_UPDATE_LOADING,
	SELF_SCHEMA_UPDATE_ERROR,
	SELF_SCHEMA_DELETE_LOADING
} from '../types';

const initialState = {
	self_schema_create_loading: false,
	all_self_schemas_loading: false,
	all_self_schemas: { count: 0, schemas: [] },
	self_schema_update_loading: false,
	self_schema_delete_loading: false,
	self_schemas_errors: {}
}

export default function selfSchemasReducer(state = initialState, action){
	switch(action.type){
		case CLEAR_SELF_SCHEMAS_ERRORS:
			return {
				...state,
				self_schemas_errors: {}
			}
		case SELF_SCHEMA_CREATE_LOADING:
			return {
				...state,
				self_schema_create_loading: action.payload
			}
		case ALL_SELF_SCHEMAS_LOADING:
			return {
				...state,
				all_self_schemas_loading: action.payload
			}
		case ALL_SELF_SCHEMAS_GET:
			return {
				...state,
				all_self_schemas: {
					count: action.payload.count,
					schemas: action.payload.schemas
				}
			}
		case SELF_SCHEMA_UPDATE_LOADING:
			return {
				...state,
				self_schema_update_loading: action.payload
			}
		case SELF_SCHEMA_DELETE_LOADING:
			return {
				...state,
				self_schema_delete_loading: action.payload
			}
		case SELF_SCHEMA_CREATE_ERROR:
		case ALL_SELF_SCHEMAS_ERROR:
		case SELF_SCHEMA_UPDATE_ERROR:
			return {
				...state,
				self_schemas_errors: {
					...state.self_schemas_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
