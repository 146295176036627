import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_SELF_CONFIGS_ERRORS,
	SELF_CONFIG_CREATE_LOADING,
	SELF_CONFIG_CREATE_ERROR,
	ALL_SELF_CONFIGS_LOADING,
	ALL_SELF_CONFIGS_GET,
	ALL_SELF_CONFIGS_ERROR,
	SELF_CONFIG_UPDATE_LOADING,
	SELF_CONFIG_UPDATE_ERROR,
	SELF_CONFIG_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_self_configs_errors = () => dispatch => {
	dispatch({
		type: CLEAR_SELF_CONFIGS_ERRORS
	});
}

export const self_config_create = (messageApi, selfConfigBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: SELF_CONFIG_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/configurations/create';

	Axios.post(url, selfConfigBody)
	.then((res) => {
		messageApi.success(i18next.t('configForm.create.msg.success'), 4);

		handleCancel();

		dispatch({
			type: SELF_CONFIG_CREATE_LOADING,
			payload: false
		});

		dispatch(get_all_self_configurations(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SELF_CONFIG_CREATE_ERROR,
			payload: { type: 'self_config_create', msg: err.message }
		});

		dispatch({
			type: SELF_CONFIG_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_self_configurations = (filters) => dispatch => {
	dispatch({
		type: ALL_SELF_CONFIGS_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/self/configurations?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newConfigsArray = add_key_prop(res.data.configurations);
		res.data.configurations = newConfigsArray;

		dispatch({
			type: ALL_SELF_CONFIGS_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_SELF_CONFIGS_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_SELF_CONFIGS_ERROR,
			payload: { type: 'all_self_configurations', msg: err.message }
		});

		dispatch({
			type: ALL_SELF_CONFIGS_LOADING,
			payload: false
		});
	})
}

export const self_config_update = (messageApi, selfConfigId, selfConfigBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: SELF_CONFIG_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/configurations/${selfConfigId}/update`;

	Axios.put(url, selfConfigBody)
	.then((res) => {
		messageApi.success(i18next.t('configForm.edit.msg.success'), 4);
		
		handleCancel();

		dispatch({
			type: SELF_CONFIG_UPDATE_LOADING,
			payload: false
		});

		dispatch(get_all_self_configurations(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SELF_CONFIG_UPDATE_ERROR,
			payload: { type: 'self_config_update', msg: err.message }
		});

		dispatch({
			type: SELF_CONFIG_UPDATE_LOADING,
			payload: false
		});
	})
}

export const self_config_delete = (messageApi, selfConfigId, filters) => dispatch => {
	dispatch({
		type: SELF_CONFIG_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/self/configurations/${selfConfigId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('configForm.delete.msg.success'), 4);

		dispatch({
			type: SELF_CONFIG_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_self_configurations(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('configForm.delete.msg.error'), 4);

		dispatch({
			type: SELF_CONFIG_DELETE_LOADING,
			payload: false
		});
	})
}
