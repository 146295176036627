import {
	CLEAR_SELF_ERRORS,
	UPLOAD_FILE_ERROR,
	ALL_FILES_LOADING,
	ALL_FILES_GET,
	ALL_FILES_ERROR,
	FILE_INFO_LOADING,
	FILE_INFO_GET,
	FILE_INFO_ERROR,
	DOWNLOAD_FILE_LOADING,
	FILE_CHECK_LOADING,
	FILE_CHECK_GET,
	FILE_CHECK_ERROR,
	DOWNLOAD_VALIDATION_LOADING,
	FILE_CONFIRM_LOADING,
	FILE_DISCARD_LOADING,
	LOAD_CREATE_LOADING,
	LOAD_CREATE_ERROR,
	ALL_LOADS_LOADING,
	ALL_LOADS_GET,
	ALL_LOADS_ERROR,
	LOAD_UPDATE_LOADING,
	LOAD_UPDATE_ERROR,
	LOAD_DELETE_LOADING,
	LOADS_DATA_LOADING,
	LOADS_DATA_GET,
	LOADS_DATA_ERROR
} from '../types';

const initialState = {
	all_files_loading: false,
	all_files: { count: 0, files: [] },
	file_info_loading: false,
	file_info: {},
	download_file_loading: false,
	file_check_loading: false,
	file_check: {},
	download_validation_loading: false,
	file_confirm_loading: false,
	file_discard_loading: false,
	load_create_loading: false,
	all_loads_loading: false,
	all_loads: { count: 0, loads: [] },
	load_update_loading: false,
	load_delete_loading: false,
	loads_data_loading: false,
	loads_data: [],
	self_errors: {}
}

export default function selfReducer(state = initialState, action){
	switch(action.type){
		case CLEAR_SELF_ERRORS:
			return {
				...state,
				self_errors: {}
			}
		case ALL_FILES_LOADING:
			return {
				...state,
				all_files_loading: action.payload
			}
		case ALL_FILES_GET:
			return {
				...state,
				all_files: {
					count: action.payload.count,
					files: action.payload.files
				}
			}
		case FILE_INFO_LOADING:
			return {
				...state,
				file_info_loading: action.payload
			}
		case FILE_INFO_GET:
			return {
				...state,
				file_info: action.payload
			}
		case DOWNLOAD_FILE_LOADING:
			return {
				...state,
				download_file_loading: action.payload
			}
		case FILE_CHECK_LOADING:
			return {
				...state,
				file_check_loading: action.payload
			}
		case FILE_CHECK_GET:
			delete state.self_errors.check_file
			return {
				...state,
				file_check: action.payload
			}
		case DOWNLOAD_VALIDATION_LOADING:
			return {
				...state,
				download_validation_loading: action.payload
			}
		case FILE_CONFIRM_LOADING:
			return {
				...state,
				file_confirm_loading: action.payload
			}
		case FILE_DISCARD_LOADING:
			return {
				...state,
				file_discard_loading: action.payload
			}
		case LOAD_CREATE_LOADING:
			return {
				...state,
				load_create_loading: action.payload
			}
		case ALL_LOADS_LOADING:
			return {
				...state,
				all_loads_loading: action.payload
			}
		case ALL_LOADS_GET:
			return {
				...state,
				all_loads: {
					count: action.payload.count,
					loads: action.payload.loads
				}
			}
		case LOAD_UPDATE_LOADING:
			return {
				...state,
				load_update_loading: action.payload
			}
		case LOAD_DELETE_LOADING:
			return {
				...state,
				load_delete_loading: action.payload
			}
		case LOADS_DATA_LOADING:
			return {
				...state,
				loads_data_loading: action.payload
			}
		case LOADS_DATA_GET:
			return {
				...state,
				loads_data: action.payload
			}
		case UPLOAD_FILE_ERROR:
		case ALL_FILES_ERROR:
		case FILE_INFO_ERROR:
		case FILE_CHECK_ERROR:
		case LOAD_CREATE_ERROR:
		case ALL_LOADS_ERROR:
		case LOAD_UPDATE_ERROR:
		case LOADS_DATA_ERROR:
			return {
				...state,
				self_errors: {
					...state.self_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
