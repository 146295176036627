import Axios from 'axios';
import i18next from 'i18next';

// Actions
import { get_route_locations } from './routesActions';

export const update_location_info = (messageApi, routeInfo, locationInfo) => dispatch => {
	const hideLoadingMsg = messageApi.loading(i18next.t('locationsUpdates.msg.loading'), 0);

	const location_id = locationInfo._id.$oid;

	let formData = new FormData();
	formData.append('organization', routeInfo.organization.$oid);
	formData.append('workspace', routeInfo.workspace.$oid);
	formData.append('name', locationInfo.name);
	formData.append('description', locationInfo.description);
	formData.append('code', locationInfo.code);
	formData.append('location_lat', locationInfo.lat);
	formData.append('location_lon', locationInfo.lon);

	let url = process.env.REACT_APP_SERVER_URL + `/api/locations/${location_id}/update`;

	Axios.put(url, formData, 
		{
			headers: { 'Content-Type': 'multipart/form-data' }
		}
	)
	.then((res) => {
		hideLoadingMsg();
		messageApi.success(i18next.t('locationsUpdates.msg.success'), 4);

		dispatch(get_route_locations(routeInfo._id.$oid));
	}).catch((err) => {
		// console.log(err);
		hideLoadingMsg();
		messageApi.error(i18next.t('locationsUpdates.msg.error'), 4);
	})
}

export const update_location_data = (showMsg, messageApi, routeInfo, locationInfo) => dispatch => {
	let hideLoadingMsg = null;
	
	if (showMsg) {
		hideLoadingMsg = messageApi.loading(i18next.t('locationsUpdates.msg.loading'), 0);
	}

	const location_id = locationInfo._id.$oid;

	let url = process.env.REACT_APP_SERVER_URL + `/api/locations/${location_id}/data/update`;
	
	const body = {
		organization: routeInfo.organization.$oid,
		workspace: routeInfo.workspace.$oid,
		data: locationInfo.data
	};

	Axios.put(url, body)
	.then((res) => {
		if (showMsg) {
			hideLoadingMsg();
			messageApi.success(i18next.t('locationsUpdates.msg.success'), 4);
		}

		dispatch(get_route_locations(routeInfo._id.$oid));
	}).catch((err) => {
		// console.log(err);
		if (showMsg) {
			hideLoadingMsg();
			messageApi.error(i18next.t('locationsUpdates.msg.error'), 4);
		}
	})
}
