import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Button, Card, Col, Divider, Flex, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, DownloadOutlined, SyncOutlined, ToolFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { confirm_file, discard_file, download_file, download_validation_file, file_check_retry, file_process, get_file_info } from '../../redux/actions/selfActions';

// Utils
import { get_load_source_desc, get_load_status_desc, get_load_type_desc } from '../../utils/get_status_desc';
import { get_load_status_color } from '../../utils/get_color_status';
import check_user_role from '../../utils/check_user_role';

const FileInfoCard = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const { file_id } = useParams();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { user } = useSelector(state => state.auth);

	const { file_info_loading, file_info, download_file_loading, download_validation_loading, file_confirm_loading, file_discard_loading } = useSelector(state => state.self);

	const [size, setSize] = useState(100);
	const [isGodUser, setIsGodUser] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 576) {
				setSize('small');
			} else if (window.innerWidth < 768) {
				setSize('middle');
			} else {
				setSize(100);
			}
		};

		// Set size at the initial load
		handleResize();

		window.addEventListener('resize', handleResize);

		const godUser = check_user_role(user.role);
		setIsGodUser(godUser);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		dispatch(get_file_info(file_id));
	}, []);

	const download = () => {
		let fileName = file_info?.description;
		// Remove dashes and colons
		fileName = fileName.replace(/[:\-]/g, '');

		// Replace spaces with underscores
		fileName = fileName.replace(/ /g, '_');

		// Replace multiple underscores with a single underscore
		fileName = fileName.replace(/_+/g, '_');

		// Add csv extension
		fileName = `${fileName.toLowerCase()}.csv`;

		dispatch(download_file(messageApi, file_id, fileName));
	}

	const download_validation = () => {
		let fileName = `file_${file_id}_validation.json`;

		dispatch(download_validation_file(messageApi, file_id, fileName));
	}

	const confirm = () => {
		dispatch(confirm_file(messageApi, file_id));
	}

	const discard = () => {
		dispatch(discard_file(messageApi, file_id));
	}

	return (
		<Flex justify='center'>
			<Card
				bordered={false}
				style={{ width: 1000 }}
			>
				<Skeleton loading={file_info_loading} active>
					<Row style={{ textAlign: 'center' }}>
						<Col span={24}>
							<Space direction='vertical'>
								<Typography.Title level={4} copyable={{ text: file_info?._id?.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
									{file_info?.name}
								</Typography.Title>
								<Typography.Text type='secondary'>{file_info?.description}</Typography.Text>
							</Space>

							<Divider />

							<Space className='responsive-space' direction='horizontal' size={size}>
								<Typography.Text>
									<strong>{t('routesTable.headers.status')}:</strong> <Tag color={get_load_status_color(file_info?.status)}>{get_load_status_desc(file_info?.status)}</Tag>
								</Typography.Text>
								<Typography.Text>
									<strong>{t('loadStatusDetails.card.source')}:</strong> {get_load_source_desc(file_info?.source)}
								</Typography.Text>
								<Typography.Text>
									<strong>{t('loadFileInput.loadType')}:</strong> {get_load_type_desc(file_info?.load_type)}
								</Typography.Text>
							</Space>

							<Divider />

							<Space className='responsive-space' direction='horizontal' size={size}>
								<Typography.Text>
									<strong>{t('routesTable.headers.user')}:</strong> {file_info?.owner?.$oid}
								</Typography.Text>
								<Typography.Text>
									<strong>{t('loadStatus.statusTable.date')}:</strong> {new Date(file_info?.date?.$date).toLocaleString(_ls.i18nextLng, { hour12: true })}
								</Typography.Text>
							</Space>

							<Divider />

							<Space className='responsive-space' direction='horizontal' size={size}>
								<Button
									type='primary'
									icon={<DownloadOutlined />}
									loading={download_file_loading}
									onClick={() => download()}
								>
									{t('loadStatusDetails.download.button')}
								</Button>
								{(isGodUser && file_info?.validation !== null) &&
									<Button
										type='primary'
										style={{ backgroundColor: '#722ed1' }} 
										icon={<DownloadOutlined />}
										loading={download_validation_loading}
										onClick={() => download_validation()}
									>
										{t('loadStatusDetails.download.validationBtn')}
									</Button>
								}
								{file_info?.status === 3 &&
									<Space className='responsive-space' direction='horizontal' size={size}>
										<Button
											type='primary'
											className='success-btn'
											icon={<CheckCircleFilled />}
											loading={file_confirm_loading}
											onClick={() => confirm()}
										>
											{t('loadStatus.statusTable.actionButtons.confirm')}
										</Button>
										<Button
											type='primary'
											danger
											icon={<CloseCircleFilled />}
											loading={file_discard_loading}
											onClick={() => discard()}
										>
											{t('loadStatus.statusTable.actionButtons.discard')}
										</Button>
									</Space>
								}
							</Space>
							
							{isGodUser &&
								<>
									<Divider />
									<Space className='responsive-space' direction='horizontal' size={size}>
										<Button
											type='primary'
											style={{ backgroundColor: '#1677ff' }}
											icon={<SyncOutlined />}
											loading={download_file_loading}
											onClick={() => dispatch(file_check_retry(messageApi, file_info))}
										>
											Check
										</Button>
										<Button
											type='primary'
											style={{ backgroundColor: '#faad14' }}
											icon={<ToolFilled />}
											loading={download_file_loading}
											onClick={() => dispatch(file_process(messageApi, file_id))}
										>
											Process
										</Button>
									</Space>
								</>
							}
						</Col>
					</Row>
				</Skeleton>
			</Card>
		</Flex>
	)
}

FileInfoCard.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default FileInfoCard;
