const check_user_role = (userRole) => {
	if (process.env.REACT_APP_RUNTIME === 'Production') {
		return userRole === '6169a0c1283fbe31af55ab82'
	}
	else {
		return userRole === '6169a0c1283fbe31af55ab82'
	}
};

export default check_user_role;
