import Axios from 'axios';
import i18next from 'i18next';

import {
	ROUTES_LOADING,
	ROUTES_GET,
	ROUTES_ERROR,
	ROUTE_DATA_LOADING,
	ROUTE_DATA_GET,
	ROUTE_DATA_ERROR,
	ROUTE_LOCATIONS_LOADING,
	ROUTE_LOCATIONS_GET,
	ROUTE_LOCATIONS_ERROR,
	ROUTE_CYCLE_GET,
	ROUTE_CYCLE_ERROR
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';
import { add_location_to_route, remove_location_from_route } from '../../utils/handle_route_locations';

export const get_routes = (filters, routeId) => dispatch => {
	dispatch({
		type: ROUTES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/routes?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		const newRoutesArray = add_key_prop(res.data.routes);
		res.data.routes = newRoutesArray.filter((route) => route._id.$oid !== routeId);

		dispatch({
			type: ROUTES_GET,
			payload: res.data
		});

		dispatch({
		type: ROUTES_LOADING,
		payload: false
	});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ROUTES_ERROR,
			payload: { type: 'routes', msg: err.message }
		});

		dispatch({
		type: ROUTES_LOADING,
		payload: false
	});
	})
}

export const get_route_data = (routeId) => dispatch => {
	dispatch({
		type: ROUTE_DATA_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${routeId}/data`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ROUTE_DATA_GET,
			payload: res.data
		});

		dispatch({
			type: ROUTE_DATA_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ROUTE_DATA_ERROR,
			payload: { type: 'route_data', msg: err.message }
		});

		dispatch({
			type: ROUTE_DATA_LOADING,
			payload: false
		});
	})
}

export const get_route_locations = (route_id) => dispatch => {
	dispatch({
		type: ROUTE_LOCATIONS_LOADING
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/locations`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		const newLocationsArray = add_key_prop(res.data.locations);
		res.data.locations = newLocationsArray;

		dispatch({
			type: ROUTE_LOCATIONS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ROUTE_LOCATIONS_ERROR,
			payload: { type: 'route_locations', msg: err.message }
		})
	})
}

export const get_route_cycle = (route_id) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/cycle`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ROUTE_CYCLE_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err)
		dispatch({
			type: ROUTE_CYCLE_ERROR,
			payload: { type: 'route_cycle', msg: err.message }
		})
	})
}

export const load_route = (showMsg, messageApi, routeInfo, routeCycle) => dispatch => {
	let hideLoadingMsg = null;

	if (showMsg) {
		hideLoadingMsg = messageApi.loading(i18next.t('routeUpdates.msg.loading'), 0);
	}

	const route_id = routeInfo._id.$oid;

	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/load`;
	
	const body = {
		organization: routeInfo.organization.$oid,
		workspace: routeInfo.workspace.$oid,
		module: routeInfo.module.$oid || routeInfo.module._id.$oid,
		cycle: routeCycle._id.$oid,
		area_type: 6
	};

	Axios.post(url, body)
	.then((res) => {
		if (showMsg) {
			hideLoadingMsg();
			messageApi.success(i18next.t('routeUpdates.msg.success'), 4);
		}
	}).catch((err) => {
		// console.log(err);
		if (showMsg) {
			hideLoadingMsg();
			messageApi.error(i18next.t('routeUpdates.msg.error'), 4);
		}
	})
}

export const update_route_locations = (messageApi, handleCancel, routeInfo, action, locationsToMove, routeCycle) => dispatch => {
	const hideLoadingMsg = messageApi.loading(`${i18next.t('locationsMove.update.loading')} ${routeInfo.code}`, 0);

	const route_id = routeInfo._id.$oid;

	let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route_id}/locations/update`;

	let locationsArray = [];

	if (action === 'add') {
		locationsArray = add_location_to_route(routeInfo.locations, locationsToMove);
	}
	else if (action === 'remove') {
		locationsArray = remove_location_from_route(routeInfo.locations, locationsToMove);
	}
	
	const body = {
		locations: locationsArray
	};

	Axios.post(url, body)
	.then((res) => {
		hideLoadingMsg();
		messageApi.success(`${i18next.t('filters.route')} ${routeInfo.code} ${i18next.t('locationsMove.update.success')}`, 4);

		handleCancel();

		// Load route into cache
		dispatch(load_route(false, messageApi, routeInfo, routeCycle));
		// Get locations of the route
		dispatch(get_route_locations(route_id));
	}).catch((err) => {
		// console.log(err);
		hideLoadingMsg();
		messageApi.error(`${i18next.t('locationsMove.update.error')} ${routeInfo.code}!`, 4);
	})
}

export const move_routes = (messageApi, handleCancel, routesToMove, destinationGroup, filters) => async dispatch => {
	const hideLoadingMsg = messageApi.loading(i18next.t('routesMove.update.loading'), 0);

	try {
		await Promise.all(routesToMove.map(async (route) => {
			let url = process.env.REACT_APP_SERVER_URL + `/api/routes/${route._id.$oid}/move`;
			
			const routeBody = {
				region: route.region.$oid,
				zone: route.zone.$oid,
				depot: route.depot.$oid,
				group: destinationGroup.id
			};

			await Axios.put(url, routeBody);
		}));

		hideLoadingMsg();
		messageApi.success(i18next.t('routesMove.update.success'), 4);

		handleCancel();

		// Get routes
		delete filters.route;
		let routesFilters = { ...filters, skip: 0, limit: 10 };
		dispatch(get_routes(routesFilters, null));
	} catch (error) {
		// console.log(error);
		hideLoadingMsg();
		messageApi.error(i18next.t('routesMove.update.error'), 4);
	}
}
