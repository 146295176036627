import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../../filters/OrgFilters';
import LoadModal from './LoadModal';
import LoadsTable from './LoadsTable';

// Actions
import { get_cycles } from '../../../redux/actions/organizationActions';
import { get_all_loads } from '../../../redux/actions/selfActions';

const Loads = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [update, setUpdate] = useState(false);
	const [loadToUpdate, setLoadToUpdate] = useState({});
	const [pageSize] = useState(10);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '' && orgFilters.module !== '') {
			const filters = { ...orgFilters, skip: 0, limit: 10 };
			dispatch(get_all_loads(filters));
		}
	}, [orgFilters]);

	useEffect(() => {
		if (orgFilters.workspace !== '' && orgFilters.module !== '') {
			dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
		}
	}, [orgFilters]);

	const createLoad = () => {
		setIsModalOpen(true);
		setUpdate(false);
		setLoadToUpdate({});
	}
	
	return (
		<Row justify='center'>
			<Col lg={17} md={17} sm={24} xs={24}>
				<LoadModal
					messageApi={messageApi}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					orgFilters={orgFilters}
					update={update}
					loadToUpdate={loadToUpdate}
					pageSize={pageSize}
					skip={skip}
				/>

				<Row justify='center'>
					<Col lg={8} md={8} sm={24} xs={24}></Col>
					<Col lg={8} md={8} sm={24} xs={24}>
						<Typography.Title className='text-center' level={2}>{t('navbar.options.load.loads')}</Typography.Title>
					</Col>
					<Col style={{ textAlign: 'end' }} lg={8} md={8} sm={24} xs={24}>
						<Button
							type='primary'
							size='large'
							onClick={() => createLoad()}
						>
							{t('loadForm.create.title').split(' ')[0]}
						</Button>
					</Col>
				</Row>

				<OrgFilters
					orgFilters={orgFilters}
					setOrgFilters={setOrgFilters}
					displayFilters={{ organization: true, workspace: true, module: true }}
				/>

				<LoadsTable
					messageApi={messageApi}
					orgFilters={orgFilters}
					setIsModalOpen={setIsModalOpen}
					setUpdate={setUpdate}
					setLoadToUpdate={setLoadToUpdate}
					pageSize={pageSize}
					skip={skip}
					setSkip={setSkip}
				/>
			</Col>
		</Row>
	);
}

Loads.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Loads;
