import {
	get_load_type_desc,
	get_load_scope_desc,
	get_load_field_type_desc,
	get_area_type_desc,
	get_cycle_scope_desc
} from './get_status_desc';

// LOAD_TYPE_NONE = 0
// LOAD_TYPE_CYCLE = 1
// LOAD_TYPE_SINGLE = 2
// LOAD_TYPE_UPDATE = 3
export const load_types_options = () => [
  get_load_type_desc(1),
	get_load_type_desc(2),
	get_load_type_desc(3)
];

// LOAD_SCOPE_NONE = 0
// LOAD_SCOPE_GLOBAL = 1
// LOAD_SCOPE_ORGANIZATION = 2
// LOAD_SCOPE_WORKSPACE = 3
// LOAD_SCOPE_MODULE = 4
export const load_scope_options = () => [
  get_load_scope_desc(1),
	get_load_scope_desc(2),
	get_load_scope_desc(3),
	get_load_scope_desc(4)
];

// LOAD_SCHEMA_NONE = 0
// LOAD_SCHEMA_INT = 1
// LOAD_SCHEMA_FLOAT = 2
// LOAD_SCHEMA_STRING = 3
export const load_field_type_options = () => [
  get_load_field_type_desc(1),
	get_load_field_type_desc(2),
	get_load_field_type_desc(3)
];

// AREA_TYPE_NONE = 0
// AREA_TYPE_ORGANIZATION = 1
// AREA_TYPE_REGION = 2
// AREA_TYPE_ZONE = 3
// AREA_TYPE_DEPOT = 4
// AREA_TYPE_GROUP = 5
// AREA_TYPE_ROUTE = 6
// AREA_TYPE_LOCATION = 7
export const area_type_options = () => [
  get_area_type_desc(1),
	get_area_type_desc(2),
	get_area_type_desc(3),
	get_area_type_desc(4),
	get_area_type_desc(5),
	get_area_type_desc(6)
];

// CYCLE_SCOPE_NONE = 0
// CYCLE_SCOPE_COMPLETE = 1
// CYCLE_SCOPE_SELECTED = 2
export const cycle_scope_options = () => [
  get_cycle_scope_desc(1),
	get_cycle_scope_desc(2)
];
