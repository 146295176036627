import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Col, Input, Row, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';

// Components
import LocationsMove from './LocationsMove';

// Actions
import { update_location_data, update_location_info } from '../../redux/actions/locationsActions';
import { load_route } from '../../redux/actions/routesActions';

// Utils
import isEmpty from '../../utils/isEmpty';

const EditCell = (props) => {
	const { editable, dataIndex, record, searchText, onChange } = props;

	const { t } = useTranslation();

	const inputRef = useRef();

	return (editable
		?	<Input
				ref={inputRef}
				defaultValue={record[dataIndex]}
				onChange={() => onChange(dataIndex, inputRef.current.input.value)}
			/>
		:	<Typography.Text copyable={dataIndex === 'code' && { text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
				{dataIndex === 'code'
					?	<Highlighter
							highlightStyle={{ backgroundColor: '#FFE58F', padding: 0 }}
							searchWords={[searchText]}
							autoEscape
							textToHighlight={record[dataIndex] ? record[dataIndex].toString() : ''}
						/>
					:	record[dataIndex]
				}
			</Typography.Text>
	);
}

EditCell.propTypes = {
	editable: PropTypes.bool.isRequired,
	dataIndex: PropTypes.string.isRequired,
	record: PropTypes.object.isRequired,
	searchText: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}


const LocationsTable = (props) => {
	const { messageApi, routeInfo, locations, updatesMade, setUpdatesMade } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { route_locations, route_cycle } = useSelector(state => state.routes);

	const [editable, setEditable] = useState(false);
	const [locationToEdit, setLocationToEdit] = useState({});
	const [updatedInfoAndData, setUpdatedInfoAndData] = useState(false);
	const [locationsToMove, setLocationsToMove] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const onChangeLocationInfo = (dataIndex, value) => {
		setUpdatedInfoAndData(true);

		let dataKey = '';
		switch (dataIndex) {
			case 'code':
				dataKey = 'cuc';
				break;
			case 'lat':
				dataKey = 'coordy';
				break;
			case 'lon':
				dataKey = 'coordx';
				break;
			default:
				break;
		}

		setLocationToEdit({
			...locationToEdit, [dataIndex]: value,
			data: {
				...locationToEdit.data,
				[dataKey]: dataKey !== 'cuc' ? value.toUpperCase() : value
			}
		});
	}

	const onChangeLocationData = (dataIndex, value) => {
		setLocationToEdit({ ...locationToEdit, data: { ...locationToEdit.data, [dataIndex]: value.toUpperCase() } });
	}

	const rowSelection = {
		/**
		 * @param {any} selectedRowKeys - Selected row key value
		 * @param {object} selectedRows - Selected row data
		 */
		onChange: (selectedRowKeys, selectedRows) => {
			setLocationsToMove(selectedRows);
		},
		getCheckboxProps: (record) => ({
			name: record.name
		})
	};

	const cancel = () => {
		setEditable(false);
		setLocationToEdit({});
		setLocationsToMove([]);
	}

	const save = (locationInfo) => {
		setUpdatesMade(true);

		if (updatedInfoAndData) {
			setUpdatedInfoAndData(false);
			dispatch(update_location_info(messageApi, routeInfo, locationInfo));
			dispatch(update_location_data(false, messageApi, routeInfo, locationInfo));
		}
		else {
			dispatch(update_location_data(true, messageApi, routeInfo, locationInfo));
		}
		
		cancel();
	}

	const [searchText, setSearchText] = useState('');
	const searchInput = useRef(null);

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};
	
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`${t('locations.table.search.search')} CUC`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type='primary'
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size='small'
						style={{ width: 90 }}
					>
						{t('locations.table.search.search')}
					</Button>
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size='small'
						style={{ width: 90 }}
					>
						{t('locations.table.search.reset')}
					</Button>
				</Space>
			</div>
		),
		filterIcon: () => (
			<SearchOutlined style={{ color: 'white' }} />
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (_, record) =>
			<EditCell
				editable={editable && locationToEdit._id.$oid === record.key}
				dataIndex='code'
				record={record}
				searchText={searchText}
				onChange={onChangeLocationInfo}
			/>
	});

	const finishChanges = () => {
		if (isEmpty(route_cycle)) {
			messageApi.error(t('cycle.routeCycle.msg.error'), 4);
		}
		else {
			dispatch(load_route(true, messageApi, route_locations, route_cycle));
			setUpdatesMade(false);
		}
	}

	const columns = [
		{
			title: 'CUC',
			dataIndex: 'code',
			key: 'code',
			...getColumnSearchProps('code')
		},
		{
			title: 'Latitud',
			dataIndex: 'lat',
			key: 'lat',
			render: (_, record) => (
				<EditCell
					editable={editable && locationToEdit._id.$oid === record.key}
					dataIndex='lat'
					record={record}
					onChange={onChangeLocationInfo}
				/>
			)
		},
		{
			title: 'Longitud',
			dataIndex: 'lon',
			key: 'lon',
			render: (_, record) => (
				<EditCell
					editable={editable && locationToEdit._id.$oid === record.key}
					dataIndex='lon'
					record={record}
					onChange={onChangeLocationInfo}
				/>
			)
		},
		{
			title: 'Promorack',
			dataIndex: ['data', 'promorack'],
			key: 'promorack',
			render: (_, record) => (
				<EditCell
					editable={editable && locationToEdit._id.$oid === record.key}
					dataIndex='promorack'
					record={record?.data}
					onChange={onChangeLocationData}
				/>
			)
		},
		{
			title: 'Ebrack',
			dataIndex: ['data', 'ebrack'],
			key: 'ebrack',
			render: (_, record) => (
				<EditCell
					editable={editable && locationToEdit._id.$oid === record.key}
					dataIndex='ebrack'
					record={record?.data}
					onChange={onChangeLocationData}
				/>
			)
		},
		{
			title: 'Rack Garrafón',
			dataIndex: ['data', 'rack_garrafon'],
			key: 'rack_garrafon',
			render: (_, record) => (
				<EditCell
					editable={editable && locationToEdit._id.$oid === record.key}
					dataIndex='rack_garrafon'
					record={record?.data}
					onChange={onChangeLocationData}
				/>
			)
		},
		{
			title: 'Retornables',
			dataIndex: ['data', 'rack_retornables'],
			key: 'rack_retornables',
			render: (_, record) => (
				<EditCell
					editable={editable && locationToEdit._id.$oid === record.key}
					dataIndex='rack_retornables'
					record={record?.data}
					onChange={onChangeLocationData}
				/>
			)
		},
		{
			title: t('routesTable.headers.action'),
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					{editable && locationToEdit._id.$oid === record.key
						?	<Space>
								<Button
									type='primary'
									shape='circle'
									size='large'
									className='success-btn'
									icon={<span className='material-symbols-outlined'>save</span>}
									onClick={() => save(locationToEdit)}
								/>
								<Button
									type='primary'
									shape='circle'
									size='large'
									danger
									icon={<span className='material-symbols-outlined'>cancel</span>}
									onClick={() => cancel()}
								/>
							</Space>
						:	<Button
								type='primary'
								shape='circle'
								size='large'
								icon={<i className='bi bi-pencil-fill'></i>}
								onClick={() => { setEditable(true); setLocationToEdit(record); }}
							/>
					}
				</Space>
			)
		}
	];

	return (
		<div>
			<LocationsMove
				messageApi={messageApi}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				routeInfo={routeInfo}
				locationsToMove={locationsToMove}
				routeCycle={route_cycle}
			/>

			<Row justify='end' style={{ textAlign: 'end', marginBottom: '0.5rem' }}>
				<Space size={20}>
					<Tooltip title={t('locations.finishChanges')}>
						<Button
							className={updatesMade && 'help-btn'}
							type='primary'
							shape='circle'
							size='large'
							disabled={!updatesMade}
							icon={<span className='material-symbols-outlined'>save</span>}
							onClick={() => finishChanges()}
						/>
					</Tooltip>
					<Tooltip title={t('locations.move')}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							disabled={locationsToMove.length === 0}
							icon={<span className='material-symbols-outlined'>move_group</span>}
							onClick={() => setIsModalOpen(true)}
						/>
					</Tooltip>
				</Space>
			</Row>
			<Spin spinning={locations.length === 0} tip={t('locations.loading')} size='large'>
				<Table
					rowSelection={{ ...rowSelection }}
					columns={columns}
					dataSource={locations}
					pagination={{
						pageSize: 10, 
						total: locations.length,
						showSizeChanger: false,
						showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
					}}
				/>
			</Spin>
		</div>
	)
}

LocationsTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	routeInfo: PropTypes.object.isRequired,
	locations: PropTypes.array,
	updatesMade: PropTypes.bool.isRequired,
	setUpdatesMade: PropTypes.func.isRequired
}

export default LocationsTable;
