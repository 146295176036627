import {
	CLEAR_SELF_CONFIGS_ERRORS,
	SELF_CONFIG_CREATE_LOADING,
	SELF_CONFIG_CREATE_ERROR,
	ALL_SELF_CONFIGS_LOADING,
	ALL_SELF_CONFIGS_GET,
	ALL_SELF_CONFIGS_ERROR,
	SELF_CONFIG_UPDATE_LOADING,
	SELF_CONFIG_UPDATE_ERROR,
	SELF_CONFIG_DELETE_LOADING
} from '../types';

const initialState = {
	self_config_create_loading: false,
	all_self_configs_loading: false,
	all_self_configs: { count: 0, configurations: [] },
	self_config_update_loading: false,
	self_config_delete_loading: false,
	self_configs_errors: {}
}

export default function selfConfigReducer(state = initialState, action){
	switch(action.type){
		case CLEAR_SELF_CONFIGS_ERRORS:
			return {
				...state,
				self_configs_errors: {}
			}
		case SELF_CONFIG_CREATE_LOADING:
			return {
				...state,
				self_config_create_loading: action.payload
			}
		case ALL_SELF_CONFIGS_LOADING:
			return {
				...state,
				all_self_configs_loading: action.payload
			}
		case ALL_SELF_CONFIGS_GET:
			return {
				...state,
				all_self_configs: {
					count: action.payload.count,
					configurations: action.payload.configurations
				}
			}
		case SELF_CONFIG_UPDATE_LOADING:
			return {
				...state,
				self_config_update_loading: action.payload
			}
		case SELF_CONFIG_DELETE_LOADING:
			return {
				...state,
				self_config_delete_loading: action.payload
			}
		case SELF_CONFIG_CREATE_ERROR:
		case ALL_SELF_CONFIGS_ERROR:
		case SELF_CONFIG_UPDATE_ERROR:
			return {
				...state,
				self_configs_errors: {
					...state.self_configs_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
