// AREA_STATUS_NONE = 0
// AREA_STATUS_CREATED = 1
// AREA_STATUS_AVAILABLE = 2
// AREA_STATUS_DISABLED = 3
// AREA_STATUS_REMOVED = 4
const get_route_color_status = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = '';       break;
		case 1:   desc = 'blue';   break;
		case 2:   desc = 'green';  break;
		case 3:   desc = 'orange'; break;
		case 4:   desc = 'red';    break;
		default:  desc = '';       break;
	}

	return desc;
}

// LOAD_STATUS_NONE = 0
// LOAD_STATUS_CREATED = 1
// LOAD_STATUS_REVIEW = 2
// LOAD_STATUS_WAITING = 3
// LOAD_STATUS_PROCESSING = 4
// LOAD_STATUS_GOOD = 5
// LOAD_STATUS_INCOMPLETE = 6
// LOAD_STATUS_BAD = 7
// LOAD_STATUS_REMOVED = 8
// LOAD_STATUS_ERROR = 9
const get_load_status_color = (status = 0) => {
	let color = '';

	switch (parseInt(status)) {
		case 0:   color = '';           break;
		case 1:   color = 'geekblue';   break;
		case 2:   color = 'cyan';       break;
		case 3:   color = 'warning';    break;
		case 4:   color = 'processing'; break;
		case 5:   color = 'success';    break;
		case 6:   color = 'orange';     break;
		case 7:   color = 'volcano';    break;
		case 8:   color = 'red';        break;
		case 9:   color = 'error';      break;
		default:  color = '';           break;
	}

	return color;
}

// CYCLE_STATUS_NONE = 0
// CYCLE_STATUS_CREATED = 1
// CYCLE_STATUS_STARTING = 2
// CYCLE_STATUS_AVAILABLE = 3
// CYCLE_STATUS_WAITING = 4
// CYCLE_STATUS_COMPLETED = 5
// CYCLE_STATUS_REMOVED = 6
export const get_cycle_status_color = (status = 0) => {
	let color = '';

	switch (parseInt(status)) {
		case 0:   color = '';          break;
		case 1:   color = 'blue';      break;
		case 2:   color = 'warning';   break;
		case 3:   color = 'green';     break;
		case 4:   color = 'orange';    break;
		case 5:   color = 'purple';    break;
		case 6:   color = 'red';       break;
		default:  color = '';          break;
	}

	return color;
}

export { get_route_color_status, get_load_status_color };
