import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import SelectButton from '../utils/SelectButton';

// Actions
import { get_organizations, get_workspaces, get_modules, set_org_filters } from '../../redux/actions/organizationActions';

const OrgFilters = (props) => {
	const { orgFilters, setOrgFilters, displayFilters } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { user } = useSelector(state => state.auth);

	const { organizations, workspaces, modules } = useSelector(state => state.organization);

	useEffect(() => {
		dispatch(get_organizations());
	}, []);

	useEffect(() => {
		let _organization = '';

		if (organizations.length <= 1) {
			_organization = user.organization;
		}
		else {
			_organization = _ls?.organization;
		}

		const _orgFilters = {
			...orgFilters,
			organization: _organization,
			workspace: _ls?.workspace || '',
			module: _ls?.module || ''
		}
		
		setOrgFilters(_orgFilters);
		dispatch(set_org_filters(_orgFilters));
	}, [organizations]);

	useEffect(() => {
		// setDisplayData(false);
		if (orgFilters.organization !== '') {
			dispatch(get_workspaces({ organization: orgFilters.organization }));
			dispatch(get_modules({ organization: orgFilters.organization }));
		}
	}, [orgFilters]);

	const onChangeOrgFilters = (e, name) => {
		let filters = {...orgFilters};
		let keys = Object.keys(filters);
		
		let idx = keys.indexOf(name);
		for (let index = idx; index < keys.length; index++) {
			const key = keys[index];
			filters[key] = '';
			localStorage.setItem(key, '');
		}

		if (e !== null){
			filters[name] = e.value;
			localStorage.setItem(name, e.value);
		}

		setOrgFilters(filters);
		dispatch(set_org_filters(filters));
	}

	return (
		<Row justify='center' gutter={[16, 16]}>
			{displayFilters?.organization &&
				organizations.length > 1 &&
					<Col className='gutter-row' lg={6} md={8} sm={24} xs={24}>
						<Row justify='center'>
							<Typography.Text><i className='bi bi-house me-2'></i>{t('filters.organization')}</Typography.Text>
						</Row>
						<SelectButton
							options={organizations}
							name='organization'
							value={orgFilters.organization === '' ? null : orgFilters.organization}
							onChange={onChangeOrgFilters}
							disable={false}
						/>
					</Col>
				
			}
			{displayFilters?.workspace &&
				<Col className='gutter-row' lg={6} md={8} sm={24} xs={24}>
					<Row justify='center'>
						<Typography.Text><i className='bi bi-list me-2'></i>{t('filters.workspace')}</Typography.Text>
					</Row>
					<SelectButton
						options={workspaces}
						name='workspace'
						value={orgFilters.workspace === '' ? null : orgFilters.workspace}
						onChange={onChangeOrgFilters}
						disable={false}
					/>
				</Col>
			}
			{displayFilters?.module &&
				<Col className='gutter-row' lg={6} md={8} sm={24} xs={24}>
					<Row justify='center'>
						<Typography.Text><i className='bi bi-box2 me-2'></i>{t('filters.module')}</Typography.Text>
					</Row>
					<SelectButton
						options={modules}
						name='module'
						value={orgFilters.module === '' ? null : orgFilters.module}
						onChange={onChangeOrgFilters}
						disable={false}
					/>
				</Col>
			}
		</Row>
	);
}

OrgFilters.propTypes = {
	orgFilters: PropTypes.object.isRequired,
	setOrgFilters: PropTypes.func.isRequired,
	displayFilters: PropTypes.object.isRequired
};

export default OrgFilters;