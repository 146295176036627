import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_self_configurations, self_config_delete } from '../../../redux/actions/selfConfigActions';

// Utils
import { get_load_scope_desc } from '../../../utils/get_status_desc';

const ConfigsTable = (props) => {
	const { messageApi, orgFilters, setIsModalOpen, setUpdate, setConfigToUpdate, pageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { all_self_configs_loading, all_self_configs, self_config_delete_loading } = useSelector(state => state.self_config);

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		setConfigToUpdate(record);
	}

	const deleteSelfConfig = (record) => {
		const filters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(self_config_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		...(orgFilters.organization === ''
			? [
					{
						title: t('filters.organization'),
						dataIndex: ['organization', '$oid'],
						key: 'organization'
					}
				]
			: []
		),
		...(orgFilters.workspace === ''
			? [
					{
						title: t('filters.workspace'),
						dataIndex: ['workspace', '$oid'],
						key: 'workspace'
					}
				]
			: []
		),
		...(orgFilters.module === ''
			? [
					{
						title: t('filters.module'),
						dataIndex: ['module', '$oid'],
						key: 'module'
					}
				]
			: []
		),
		{
			title: t('routesTable.headers.name'),
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			title: t('loadFileInput.description'),
			dataIndex: 'description',
			key: 'description'
		},
		{
			title: t('schemasForm.inputs.scope'),
			dataIndex: 'scope',
			key: 'scope',
			render: (text) => (
				<Typography.Text>{get_load_scope_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('configForm.inputs.bq_project'),
			dataIndex: 'bq_project',
			key: 'bq_project'
		},
		{
			title: t('configForm.inputs.bq_table'),
			dataIndex: 'bq_table',
			key: 'bq_table'
		},
		{
			title: t('configForm.inputs.bq_table_prefix'),
			dataIndex: 'bq_table_prefix',
			key: 'bq_table_prefix'
		},
		{
			title: t('loadStatus.statusTable.date'),
			dataIndex: ['date', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(_ls.i18nextLng, { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.action'),
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('configForm.edit.title').split(' ')[0]}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<i className='bi bi-pencil-fill'></i>}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('configForm.delete.popconfirm.title')}
						description={t('configForm.delete.popconfirm.description')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteSelfConfig(record)}
						okButtonProps={{
							loading: self_config_delete_loading
						}}
						okText={t('configForm.delete.popconfirm.confirm')}
						cancelText={t('configForm.delete.popconfirm.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<span className='material-symbols-outlined'>delete</span>}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pageSize;
		setSkip(skip);

		const configFilters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(get_all_self_configurations(configFilters));
	};

	return (
		<div className='mt-5'>
			<Spin spinning={all_self_configs_loading} tip={t('config.loading')} size='large'>
				<Table
					columns={columns}
					dataSource={all_self_configs.configurations}
					onChange={handleTableChange}
					pagination={{
						pageSize: pageSize,
						total: all_self_configs.count,
						showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
					}}
				/>
			</Spin>
		</div>
	)
}

ConfigsTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	orgFilters: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setConfigToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default ConfigsTable;
