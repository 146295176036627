import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_loads_data, upload_file } from '../../../redux/actions/selfActions';

// Utils
import { load_types_options } from '../../../utils/select_options';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';

const LoadFileForm = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { org_filters, cycles } = useSelector(state => state.organization);

	const { loads_data } = useSelector(state => state.self);

	const [form] = Form.useForm();
	const [loadTypesOptions, setloadTypesOptions] = useState([]);
	const [loadData, setLoadData] = useState({ type: 0, file: null });
	const [loadTypeInfo, setLoadTypeInfo] = useState({});

	const LOAD_TYPES = load_types_options();

	useEffect(() => {
		setloadTypesOptions(create_select_options_from_array(LOAD_TYPES));
	}, []);

	useEffect(() => {
		dispatch(get_loads_data({ workspace: org_filters?.workspace }));
	}, [org_filters?.workspace]);

	useEffect(() => {
		if (loadData.type !== 0) {
			const loadType = loadTypesOptions.find(type => type.value === loadData.type);

			if (loadType) {
				setLoadTypeInfo(loadType);
			}
		}
	}, [loadData.type]);

	const fileTypes = ['text/csv'];

	const beforeUpload = (file) => {
		if (!fileTypes.includes(file.type)) {
			messageApi.error(t('loadFileInput.error'), 4);
			return Upload.LIST_IGNORE;
		}
		
		setLoadData({ ...loadData, file: file });
		return false;
	};

  const onRemove = () => {
    setLoadData({ ...loadData, file: null });
  };

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.cycle) {
			const cycleInfo = cycles.find(c => c._id.$oid === changedValues.cycle);

			if (cycleInfo) {
				form.setFieldsValue({
					name: `${t('navbar.options.load.loads').slice(0, -1)}${loadTypeInfo?.name ? `: ${loadTypeInfo?.name}` : ''}`,
					description: `${t('navbar.options.load.loads').slice(0, -1)}${loadTypeInfo?.name ? `: ${loadTypeInfo?.name}` : ''} - ${cycleInfo?.description}`,
					load: undefined
				});
			}
		}

		if (changedValues.load) {
			const loadInfo = loads_data.find(load => load._id.$oid === changedValues.load);

			if (loadInfo) {
				form.setFieldsValue({
					name: `${t('navbar.options.load.loads').slice(0, -1)}${loadTypeInfo?.name ? `: ${loadTypeInfo?.name}` : ''}`,
					description: `${t('navbar.options.load.loads').slice(0, -1)}${loadTypeInfo?.name ? `: ${loadTypeInfo?.name}` : ''} - ${loadInfo?.description}`,
					cycle: undefined
				});
			}
		}
	}
	
	const onFinish = (values) => {
		const body = {
			...org_filters,
			...loadData,
			...values
		};

		dispatch(upload_file(messageApi, body));
	}

	return (
		<Form
			form={form}
			name='load_form'
			layout='vertical'
			onValuesChange={onValuesChange}
			onFinish={onFinish}
		>
			<Row justify='center' gutter={[16, 16]}>
				<Col className='gutter-row' lg={loadData.type !== 0 ? 12 : 24} md={24} sm={24} xs={24}>
					<Form.Item
						label={t('loadFileInput.loadType')}
					>
						<Select
							showSearch
							placeholder={t('loadForm.placeholder.type')}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={loadTypesOptions}
							onChange={(e) => setLoadData({ ...loadData, type: e })}
						/>
					</Form.Item>
				</Col>
				{loadData.type !== 0
					?	<Fragment>
							{loadData.type !== 1
								?	<Col className='gutter-row' lg={12} md={24} sm={24} xs={24}>
										<Form.Item
											label={t('filters.cycle')}
											name='cycle'
										>
											<Select
												showSearch
												placeholder={t('loadForm.placeholder.cycle')}
												optionFilterProp='children'
												filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
												options={create_select_options(cycles, 'name')}
											/>
										</Form.Item>
									</Col>
								:	<Col className='gutter-row' lg={12} md={24} sm={24} xs={24}>
										<Form.Item
											label={t('navbar.options.load.loads')}
											name='load'
										>
											<Select
												showSearch
												placeholder={t('loadForm.placeholder.load')}
												optionFilterProp='children'
												filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
												options={create_select_options(loads_data, 'name')}
											/>
										</Form.Item>
									</Col>
							}
						</Fragment>
					: null
				}
			</Row>
			<Row justify='center' gutter={[16, 16]}>
				<Col className='gutter-row' lg={12} md={24} sm={24} xs={24}>
					<Form.Item
						label={`${t('routesTable.headers.name')}`}
						name='name'
					>
						<Input />
					</Form.Item>
				</Col>
				<Col className='gutter-row' lg={12} md={24} sm={24} xs={24}>
					<Form.Item
						label={`${t('loadFileInput.description')}`}
						name='description'
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Form.Item>
				<Upload.Dragger
					style={{ backgroundColor: '#fafafa' }}
					beforeUpload={beforeUpload}
					onRemove={onRemove}
					maxCount={1}
				>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>{t('loadFileInput.text')}</p>
					<p className='ant-upload-hint'>{t('loadFileInput.hint')}</p>
				</Upload.Dragger>
			</Form.Item>
			<Row className='mt-4' justify='center'>
				<Col className='gutter-row' lg={4} md={6}>
					<Form.Item>
						<Button className='submit-btn' block htmlType='submit'>
							{t('loadFile.reviewBtn')}
						</Button>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	)
}

LoadFileForm.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default LoadFileForm;
