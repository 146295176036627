import React from 'react';

// Packages
import { Alert } from 'antd';
import PropTypes from 'prop-types';

const LoadFileReviewError = (props) => {
	const { error } = props;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginTop: '1.5rem'
			}}
		>
			<Alert
				style={{ width: 1000 }}
				message={error.name}
				description={error.description}
				type='error'
				showIcon
			/>
		</div>
	)
}

LoadFileReviewError.propTypes = {
	error: PropTypes.object.isRequired
}

export default LoadFileReviewError;
