export const SPANISH_TRANSLATIONS = {
	// MAIN COMPONENTS
	navbar: {
		options: {
			load: {
				title: 'Cargas',
				loads: 'Cargas',
				load: 'Subir Archivos',
				status: 'Estado de Carga'
			}
		},
		menu: {
			account: 'Cuenta',
			return: 'Regresar al menú',
			logOut: 'Cerrar sesión'
		}
	},
	landing: {
		instruction: 'Usa este dashboard para poder revisar los archivos que se cargarán al sistema.',
		continue: 'Continuar'
	},

	// FILTERS
	filters: {
		organization: 'Organización',
		workspace: 'Workspace',
		module: 'Módulo',
		submodule: 'Submódulo',
		cycle: 'Ciclo',
		areas: 'Áreas',
		region: 'Región',
		zone: 'Zona',
		depot: 'Depot',
		group: 'Grupo',
		route: 'Ruta'
	},

	// FILE UPLOAD
	loadFile: {
		load: 'Carga de Archivo',
		reviewBtn: 'Cargar',
		msg: {
			loading: 'Cargando archivo...',
			success: 'Archivo cargado correctamente!',
			error: 'Error al cargar archivo, verifica la información y vuelve a intentarlo.'
		}
	},
	loadTypeOptions: {
		restructure: 'Reestructura',
		coordinates: 'Coordenadas',
		cycle: 'Inicial',
		single: 'Única'
	},
	loadFileInput: {
		loadType: 'Tipo de carga',
		description: 'Descripción',
		text: 'Haga clic o arrastre un archivo a esta área para cargarlo',
		hint: 'Por favor, asegúrate de que el archivo que adjuntas sea de tipo csv.',
		error: 'Solo se permiten archivos csv.'
	},
	loadForm: {
		create: {
			title: 'Crear Carga',
			msg: {
				success: 'Carga creada correctamente!',
				error: 'Error al crear la carga, por favor intente de nuevo.'
			}
		},
		edit: {
			title: 'Editar Carga',
			msg: {
				success: 'Carga editada correctamente!',
				error: 'Error al editar la carga, por favor intente de nuevo.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Eliminar Carga',
				description: '¿Estás seguro de que quieres eliminar esta carga?',
				confirm: 'Confirmar',
				cancel: 'Cancelar'
			},
			msg: {
				success: 'Carga eliminada correctamente!',
				error: 'Error al eliminar la carga, por favor intente de nuevo.'
			}
		},
		placeholder: {
			organization: 'Selecciona una organización.',
			workspace: 'Selecciona un workspace.',
			module: 'Selecciona un módulo.',
			cycle: 'Selecciona un ciclo.',
			type: 'Selecciona un tipo.',
			load: 'Selecciona una carga.',
			name: 'Ingresa un nombre.',
			description: 'Ingresa una descripción.'
		},
		validationMsg: {
			organization: 'Por favor, selecciona una organización.',
			workspace: 'Por favor, selecciona un workspace.',
			module: 'Por favor, selecciona un módulo.',
			cycle: 'Por favor, selecciona un ciclo.',
			type: 'Por favor, selecciona un tipo.',
			name: 'Por favor, ingresa un nombre.',
			description: 'Por favor, ingresa una descripción.'
		},
		cancel: 'Cancelar'
	},

	// LOAD STATUS
	loadStatus: {
		statusTable: {
			loading: 'Cargando...',
			date: 'Fecha',
			actionButtons: {
				details: 'Ver Detalles',
				confirm: 'Confirmar',
				discard: 'Descartar'
			}
		}
	},
	loadStatusDetails: {
		title: 'Información del Archivo',
		card: {
			source: 'Fuente',
		},
		download: {
			button: 'Descargar',
			validationBtn: 'Validación',
			error: 'Error al descargar el archivo, por favor intente de nuevo.'
		},
		check: {
			alert: {
				msg: 'Proceso de validación en curso',
				desc: 'Actualmente, el sistema está validando este archivo. Por favor, regresa más tarde para acceder a la información que será cargada.'
			}
		},
		review: {
			title: 'Resumen de Información',
			noAction: {
				badge: 'S',
				desc: 'Sin Acción'
			},
			creation: {
				desc: 'Creación'
			},
			update: {
				badge: 'A',
				desc: 'Actualización'
			}
		},
		confirm: {
			msg: {
				success: 'El archivo ha sido confirmado para realizar la carga!',
				error: 'Error al confirmar el archivo para realizar la carga!'
			}
		},
		discard: {
			msg: {
				success: 'El archivo ha sido descartado para realizar la carga!',
				error: 'Error al descartar el archivo para realizar la carga!'
			}
		}
	},

	// CYCLE INFO
	cycle: {
		title: 'Ciclos',
		loading: 'Cargando ciclos...',
		type: 'Tipo',
		creationDate: 'Fecha de Creación',
		selectStart: 'Inicio',
		selectEnd: ' Fin',
		start: 'Inicio de ciclo:',
		end: ' Fin de ciclo:',
		workingDays: 'Días laborables:',
		currentDays: 'Días transcurridos:',
		remainingDays: 'Días restantes:',
		routeCycle: {
			msg: {
				error: 'Error al cargar el ciclo de la ruta, por favor intente de nuevo.'
			}
		},
		create: {
			title: 'Crear Ciclo',
			loading: 'Creando ciclo...',
			success: 'Ciclo creado correctamente!',
			error: 'Error al crear el ciclo, por favor intente de nuevo.'
		},
		edit: {
			title: 'Editar Ciclo',
			loading: 'Editando ciclo...',
			success: 'Ciclo editado correctamente!',
			error: 'Error al editar el ciclo, por favor intente de nuevo.'
		},
		endAction: {
			popconfirm: {
				title: 'Finalizar Ciclo',
				question: '¿Estás seguro de que quieres finalizar este ciclo?',
				description: 'Esta acción finalizará el ciclo y las rutas dejarán de estar disponibles para los usuarios.',
				confirm: 'Confirmar',
				cancel: 'Cancelar'
			},
			msg: {
				loading: 'Finalizando ciclo...',
				success: 'Ciclo finalizado correctamente!',
				error: 'Error al finalizar el ciclo, por favor intente de nuevo.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Eliminar Ciclo',
				description: '¿Estás seguro de que quieres eliminar este ciclo?',
				confirm: 'Confirmar',
				cancel: 'Cancelar'
			},
			msg: {
				loading: 'Eliminando ciclo...',
				success: 'Ciclo eliminado correctamente!',
				error: 'Error al eliminar el ciclo, por favor intente de nuevo.'
			}
		},
		placeholder: {
			reference: 'Selecciona una opción.',
		},
		validationMsg: {
			reference: 'Por favor, selecciona una opción.',
			date: 'Por favor, selecciona la duración del ciclo.'
		}
	},

	// ORGANIZATION ROUTES
	orgRoutes: {
		loading: 'Cargando rutas...',
		title: 'Rutas'
	},
	routesTable: {
		headers: {
			name: 'Nombre',
			code: 'Código',
			locations: '# Establecimientos',
			status: 'Estado',
			module: 'Módulo',
			user: 'Usuario',
			action: 'Acción'
		}
	},
	routeUpdates: {
		msg: {
			loading: 'Cargando la ruta...',
			success: 'Ruta cargada correctamente!',
			error: 'Error al cargar la ruta!'
		}
	},
	routesMove: {
		button: 'Mover rutas',
		moveError: 'Las rutas no son del mismo grupo, verifica y vuelve a intentarlo.',
		moveGroup: 'No hay grupos disponibles para mover estas rutas. Por favor, cierra este formulario.',
		steps: {
			first: {
				desc:'Selecciona el grupo de destino'
			},
			second: {
				content: {
					title: '¿Seguro que quieres mover estas rutas?',
					from: 'Del grupo:',
					to: 'Al grupo:'
				}
			}
		},
		update: {
			groupsError: 'No se encontró información de los grupos, por favor intente de nuevo.',
			loading: 'Moviendo rutas...',
			success: 'Ruta(s) movidas(s) correctamente!',
			error: 'Error al mover rutas'
		}
	},

	// LOCATIONS
	locations: {
		loading: 'Cargando establecimientos...',
		table: {
			search: {
				search: 'Buscar',
				reset: 'Resetear'
			}
		},
		finishChanges: 'Finalizar Cambios',
		move: 'Mover establecimientos'
	},
	locationsUpdates: {
		msg: {
			loading: 'Actualizando información de la tienda...',
			success: 'Información de la tienda actualizada correctamente!',
			error: 'Error al actualizar información de la tienda!'
		}
	},
	locationsMove: {
		steps: {
			first: {
				desc:'Selecciona la ruta de destino'
			},
			second: {
				title: 'Finalizar',
				content: {
					title: '¿Seguro que quieres mover estos establecimientos?',
					from: 'De:',
					to: 'A:',
					back: 'Regresar',
					confirm: 'Confirmar'
				}
			},
			continue: 'Continuar'
		},
		update: {
			routesError: 'No se encontró información de las rutas, por favor intente de nuevo.',
			loading: 'Actualizando establecimientos de la ruta',
			success: 'actualizada correctamente!',
			error: 'Error al actualizar ruta'
		}
	},

	// ERRORS
	errors: {
		title: 'Errores',
		loading: 'Cargando errores...'
	},
	errorForm: {
		inputs: {
			alias: 'Alias',
			value: 'Valor'
		},
		create: {
			title: 'Crear Error',
			msg: {
				success: 'Error creado correctamente!',
				error: 'Error al crear el error, por favor intente de nuevo.'
			}
		},
		edit: {
			title: 'Editar Error',
			msg: {
				success: 'Error editado correctamente!',
				error: 'Error al editar el error, por favor intente de nuevo.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Eliminar Error',
				description: '¿Estás seguro de que quieres eliminar este error?',
				confirm: 'Confirmar',
				cancel: 'Cancelar'
			},
			msg: {
				success: 'Error eliminado correctamente!',
				error: 'Error al eliminar el error, por favor intente de nuevo.'
			}
		},
		placeholder: {
			organization: 'Selecciona una organización.',
			alias: 'Ingresa un alias.',
			name: 'Ingresa un nombre.',
			description: 'Ingresa una descripción.',
			value: 'Ingresa un valor de error.'
		},
		validationMsg: {
			organization: 'Por favor, selecciona una organización.',
			alias: 'Por favor, ingresa un alias.',
			name: 'Por favor, ingresa un nombre.',
			description: 'Por favor, ingresa una descripción.',
			value: {
				required: 'Por favor, ingresa un valor válido de error.',
				min: 'El valor debe ser mayor a 0.'
			}
		},
		cancel: 'Cancelar'
	},

	// SCHEMAS
	schemas: {
		title: 'Esquemas',
		loading: 'Cargando esquemas...'
	},
	schemasForm: {
		inputs: {
			scope: 'Scope',
			fieldType: 'Tipo de Campo',
			code: 'Código',
			identifier: 'Indentificador',
			primary: 'Primario',
			check: 'Check',
			optional: 'Opcional'
		},
		booleanOptions: {
			true: 'Verdadero',
			false: 'Falso'
		},
		create: {
			title: 'Crear Esquema',
			msg: {
				success: 'Esquema creado correctamente!',
				error: 'Error al crear el esquema, por favor intente de nuevo.'
			}
		},
		edit: {
			title: 'Editar Esquema',
			msg: {
				success: 'Esquema editado correctamente!',
				error: 'Error al editar el esquema, por favor intente de nuevo.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Eliminar Esquema',
				description: '¿Estás seguro de que quieres eliminar este esquema?',
				confirm: 'Confirmar',
				cancel: 'Cancelar'
			},
			msg: {
				success: 'Esquema eliminado correctamente!',
				error: 'Error al eliminar el esquema, por favor intente de nuevo.'
			}
		},
		placeholder: {
			scope: 'Selecciona un scope.',
			fieldType: 'Selecciona un tipo de campo.',
		},
		validationMsg: {
			scope: 'Por favor, selecciona un scope.',
			fieldType: 'Por favor, selecciona un tipo de campo.',
			boolean: 'Por favor, selecciona una opción.'
		},
		cancel: 'Cancelar'
	},

	// CONFIGURATIONS
	config: {
		title: 'Configuraciones',
		loading: 'Cargando configuraciones...'
	},
	configForm: {
		inputs: {
			bq_project: 'Proyecto BigQuery',
			bq_table: 'Tabla BigQuery',
			bq_table_prefix: 'Prefijo de Tabla BigQuery'
		},
		create: {
			title: 'Crear Configuración',
			msg: {
				success: 'Configuración creada correctamente!',
				error: 'Error al crear la configuración, por favor intente de nuevo.'
			}
		},
		edit: {
			title: 'Editar Configuración',
			msg: {
				success: 'Configuración editada correctamente!',
				error: 'Error al editar la configuración, por favor intente de nuevo.'
			}
		},
		delete: {
			popconfirm: {
				title: 'Eliminar Configuración',
				description: '¿Estás seguro de que quieres eliminar esta configuración?',
				confirm: 'Confirmar',
				cancel: 'Cancelar'
			},
			msg: {
				success: 'Configuración eliminada correctamente!',
				error: 'Error al eliminar la configuración, por favor intente de nuevo.'
			}
		},
		placeholder: {
			bq_project: 'Ingresa un Proyecto BigQuery',
			bq_table: 'Ingresa un Tabla BigQuery',
			bq_table_prefix: 'Ingresa un Prefijo de Tabla BigQuery'
		},
		validationMsg: {
			bq_project: 'Por favor, ingresa un Proyecto BigQuery',
			bq_table: 'Por favor, ingresa un Tabla BigQuery',
			bq_table_prefix: 'Por favor, ingresa un Prefijo de Tabla BigQuery'
		},
		cancel: 'Cancelar'
	},

	// UTILS COMPONENTS
	pagination: {
		rows: 'Filas por página:',
		of: 'de'
	},
	copyableText: {
		copy: 'Copiar',
		copied: 'Copiado',
		copyId: 'Copiar ID',
		copiedId: 'ID Copiado'
	},

	// STATUS DESCRIPTIONS
	statusDesc: {
		cycle: {
			none: 'None',
			created: 'Creado',
			starting: 'Empezando',
			available: 'Disponible',
			waiting: 'Esperando',
			completed: 'Finalizado',
			removed: 'Eliminado'
		},
		route: {
			none: 'None',
			created: 'Creado',
			available: 'Disponible',
			disabled: 'Deshabilitado',
			removed: 'Eliminado'
		},
		load: {
			none: 'None',
			created: 'Creado',
			review: 'En revisión',
			waiting: 'Esperando',
			processing: 'Procesando',
			good: 'Correcto',
			incomplete: 'Incompleto',
			bad: 'Incorrecto',
			removed: 'Eliminado',
			error: 'Error'			
		},
		loadSource: {
			none: 'None',
			cloud: 'Nube',
			local: 'Local',
			service: 'Servicio',
			other: 'Otro'
		},
		loadScope: {
			none: 'None',
			global: 'Global',
			organization: 'Organización',
			workspace: 'Workspace',
			module: 'Módulo'
		},
		loadFieldType: {
			none: 'None',
			int: 'Número Entero',
			float: 'Número Decimal',
			string: 'Cadena de Texto'
		},
		areaType: {
			locations: 'Establecimientos'
		},
		cycleScope: {
			complete: 'Completo',
			selected: 'Selección'
		}
	}
};
