import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Packages
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../filters/OrgFilters';
import CyclesForm from './CyclesForm';
import CyclesTable from './CyclesTable';

// Actions
import { get_all_cycles } from '../../redux/actions/cyclesActions';

const Cycles = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [update, setUpdate] = useState(false);
	const [cycleToUpdate, setCycleToUpdate] = useState({});
	const [pageSize] = useState(10);
	const [skip, setSkip] = useState(0);

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '' && orgFilters.module !== '') {
			const filters = {
				...(orgFilters.organization.length > 0 && { organization: orgFilters.organization }),
				...(orgFilters.workspace.length > 0 && { workspace: orgFilters.workspace }),
				...(orgFilters.module.length > 0 && { module: orgFilters.module }),
				skip: skip,
				limit: pageSize
			}
			dispatch(get_all_cycles(filters));
		}
	}, [orgFilters]);

	const createCycle = () => {
		setIsModalOpen(true);
		setUpdate(false);
		setCycleToUpdate({});
	}
	
	return (
		<div>
			<Row justify='center'>
				<Col lg={17} md={17} sm={24} xs={24}>
					<CyclesForm
						messageApi={messageApi}
						isModalOpen={isModalOpen}
						setIsModalOpen={setIsModalOpen}
						orgFilters={orgFilters}
						update={update}
						cycleToUpdate={cycleToUpdate}
						setCycleToUpdate={setCycleToUpdate}
						pageSize={pageSize}
						skip={skip}
					/>

					<Row justify='center'>
						<Col lg={8} md={8} sm={24} xs={24}></Col>
						<Col lg={8} md={8} sm={24} xs={24}>
							<Typography.Title className='text-center' level={2}>{t('cycle.title')}</Typography.Title>
						</Col>
						<Col style={{ textAlign: 'end' }} lg={8} md={8} sm={24} xs={24}>
							<Button
								type='primary'
								size='large'
								disabled={orgFilters.organization.length === 0 || orgFilters.workspace.length === 0 || orgFilters.module.length === 0}
								onClick={() => createCycle()}
							>
								{t('loadForm.create.title').split(' ')[0]}
							</Button>
						</Col>
					</Row>

					<OrgFilters
						orgFilters={orgFilters}
						setOrgFilters={setOrgFilters}
						displayFilters={{ organization: true, workspace: true, module: true }}
					/>
				</Col>
			</Row>
			<CyclesTable
				messageApi={messageApi}
				orgFilters={orgFilters}
				setIsModalOpen={setIsModalOpen}
				setUpdate={setUpdate}
				setCycleToUpdate={setCycleToUpdate}
				pageSize={pageSize}
				skip={skip}
				setSkip={setSkip}
			/>
		</div>
	);
}

Cycles.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Cycles;
