import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Form, Modal, Row, Select, Space, Steps, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_groups } from '../../redux/actions/areasActions';
import { get_routes, update_route_locations } from '../../redux/actions/routesActions';

// Utils
import isEmpty from '../../utils/isEmpty';

const LocationsMove = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, routeInfo, locationsToMove, routeCycle } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { groups } = useSelector(state => state.areas);

	const { routes } = useSelector(state => state.routes);

	const [step, setStep] = useState(0);
	const [groupId, setGroupId] = useState('');
	const [destinationRoute, setDestinationRoute] = useState({ id: '', name: '', info: {} });

	const handleCancel = () => {
		setIsModalOpen(false);
		setStep(0);
	}

	useEffect(() => {
		if (!isEmpty(routeInfo)) {
			dispatch(get_groups({
				organization: routeInfo.organization.$oid,
				workspace: routeInfo.workspace.$oid,
				region: routeInfo.region.$oid,
				zone: routeInfo.zone.$oid,
				depot: routeInfo.depot.$oid
			}));
		}
	}, [routeInfo]);

	useEffect(() => {
		if (!isEmpty(groupId)) {
			dispatch(get_routes({
				organization: routeInfo.organization.$oid,
				workspace: routeInfo.workspace.$oid,
				module: routeInfo.module.$oid,
				region: routeInfo.region.$oid,
				zone: routeInfo.zone.$oid,
				depot: routeInfo.depot.$oid,
				group: groupId,
				limit: 0
			}, routeInfo._id.$oid));
		}
	}, [groupId]);

	useEffect(() => {
		if (destinationRoute.id !== '') {
			const routeFound = routes.routes.find((route) => route._id.$oid === destinationRoute.id);

			if (routeFound) {
				setDestinationRoute({ ...destinationRoute, info: routeFound });
			}
		}
	}, [destinationRoute.id]);

	const confirmMove = () => {
		if (isEmpty(routeInfo) || isEmpty(destinationRoute.info)) {
			messageApi.error(t('locationsMove.update.routesError'), 4);
			return;
		}

		// routeInfo is the origin route
		dispatch(update_route_locations(messageApi, handleCancel, routeInfo, 'remove', locationsToMove, routeCycle));
		dispatch(update_route_locations(messageApi, handleCancel, destinationRoute.info, 'add', locationsToMove, routeCycle));
	}

	return (
		<Modal
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={null}
		>
			<Steps
				current={step}
				items={[
					{
						title: t('filters.route'),
						description: t('locationsMove.steps.first.desc')
					},
					{
						title: t('locationsMove.steps.second.title'),
						description: t('locations.finishChanges')
					}
				]}
			/>

			{step === 0 && (
				<Form
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 24 }}
					layout='horizontal'
					size='default'
					style={{ maxWidth: 600, marginTop: '2rem' }}
				>
					<Form.Item label={t('filters.group')}>
						<Select
							style={{ width: '100%' }}
							options={groups.groups.map(group => ({ value: group._id.$oid, label: group.name }))}
							onChange={(value) => setGroupId(value)}
						/>
					</Form.Item>
					<Form.Item label={t('filters.route')}>
						<Select
							style={{ width: '100%' }}
							options={routes.routes.map(route => ({ value: route._id.$oid, label: route.name }))}
							onChange={(value, option) => setDestinationRoute({ id: value, name: option.label })}
						/>
					</Form.Item>
					{destinationRoute.id !== '' && (
						<Form.Item
							wrapperCol={{ offset: 11, span: 16 }}
						>
							<Button type='primary' htmlType='submit' onClick={() => setStep(step + 1)}>
								{t('locationsMove.steps.continue')}
							</Button>
						</Form.Item>
					)}
				</Form>
			)}
			{step === 1 && (
				<Row justify='center' style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '1.5rem' }}>
					<Space direction='vertical'>
						<Typography.Title level={5}>{t('locationsMove.steps.second.content.title')}</Typography.Title>

						<Space direction='vertical' style={{ marginBottom: '1rem' }}>
							{locationsToMove.map(location => (
								<Typography.Text key={location._id.$oid}>{location.code} - {location.name}</Typography.Text>
							))}
						</Space>

						<Typography.Text>
							<strong>{t('locationsMove.steps.second.content.from')}</strong> {routeInfo.name}
							<ArrowRightOutlined style={{ color: '#3e619b', marginLeft: '10px', marginRight: '10px' }} />
							<strong>{t('locationsMove.steps.second.content.to')}</strong> {destinationRoute.name}
						</Typography.Text>

						<Space direction='horizontal' size={100} style={{ marginTop: '2rem' }}>
							<Button onClick={() => setStep(step - 1)}>{t('locationsMove.steps.second.content.back')}</Button>
							<Button type='primary' onClick={() => confirmMove()}>{t('locationsMove.steps.second.content.confirm')}</Button>
						</Space>
					</Space>
				</Row>
			)}
		</Modal>
	)
}

LocationsMove.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	routeInfo: PropTypes.object.isRequired,
	locationsToMove: PropTypes.array.isRequired,
	routeCycle: PropTypes.object.isRequired
}

export default LocationsMove;
