import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Alert, Col, Flex, Row, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import FileInfoCard from '../../utils/FileInfoCard';
import LoadFileReview from './LoadFileReview';
import LoadFileReviewError from './LoadFileReviewError';

// Actions
import { check_file } from '../../../redux/actions/selfActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const LoadFileInfo = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const { file_id } = useParams();

	const dispatch = useDispatch();

	const { file_check_loading, file_check, self_errors } = useSelector(state => state.self);

	useEffect(() => {
		dispatch(check_file(true, file_id));
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (self_errors.hasOwnProperty('check_file')) {
				dispatch(check_file(false, file_id));
			}
			else {
				clearInterval(interval);
			}
		}, 1000 * 10);
	
		// Clean interval
		return () => clearInterval(interval);
	}, [self_errors]);

	return (
		<Row justify='center'>
			<Col span={24}>
				<Typography.Title className='text-center' level={2}>{t('loadStatusDetails.title')}</Typography.Title>

				<FileInfoCard
					messageApi={messageApi}
				/>

				{file_check_loading 
					?	<Flex justify='center' style={{ marginTop: '1.5rem' }}>
							<Spin size='large'/>
						</Flex>
					:	self_errors.hasOwnProperty('check_file')
						?	<Row justify='center'>
								<Alert
									style={{ marginTop: '1.5rem', width: 1000 }}
									message={t('loadStatusDetails.check.alert.msg')}
									description={t('loadStatusDetails.check.alert.desc')}
									type='info'
									showIcon
								/>
							</Row>
						:	!isEmpty(file_check)
							?	<>
									{file_check.data !== null && file_check.error === null &&
										<LoadFileReview
											treeData={file_check.data.regions}
										/>
									}
									{file_check.data === null && file_check.error !== null &&
										<LoadFileReviewError
											error={file_check.error}
										/>
									}
								</>
							:	null
				}
			</Col>
		</Row>
	)
}

LoadFileInfo.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default LoadFileInfo;
