import {
	ROUTES_LOADING,
	ROUTES_GET,
	ROUTES_ERROR,
	ROUTE_DATA_LOADING,
	ROUTE_DATA_GET,
	ROUTE_DATA_ERROR,
	ROUTE_LOCATIONS_LOADING,
	ROUTE_LOCATIONS_GET,
	ROUTE_LOCATIONS_ERROR,
	ROUTE_CYCLE_GET,
	ROUTE_CYCLE_ERROR
} from '../types';

const initialState = {
	routes_loading: false,
	routes: { count: 0, routes: [] },
	route_data_loading: false,
	route_data: {},
	route_locations_loading: false,
	route_locations: {},
	route_cycle: {},
	routes_errors: {}
}

export default function routesReducer(state = initialState, action){
	switch(action.type){
		case ROUTES_LOADING:
			return {
				...state,
				routes_loading: action.payload
			}
		case ROUTES_GET:
			return {
				...state,
				routes: {
					count: action.payload.count,
					routes: action.payload.routes
				}
			}
		case ROUTE_DATA_LOADING:
			return {
				...state,
				route_data_loading: action.payload
			}
		case ROUTE_DATA_GET:
			return {
				...state,
				route_data: action.payload
			}
		case ROUTE_LOCATIONS_LOADING:
			return {
				...state,
				route_locations_loading: true
			}
		case ROUTE_LOCATIONS_GET:
			return {
				...state,
				route_locations_loading: false,
				route_locations: action.payload
			}
		case ROUTE_CYCLE_GET:
			return {
				...state,
				route_cycle: action.payload
			}
		case ROUTES_ERROR:
		case ROUTE_LOCATIONS_ERROR:
		case ROUTE_CYCLE_ERROR:
		case ROUTE_DATA_ERROR:
			return {
				...state,
				route_locations_loading: false,
				routes_errors: {
					...state.routes_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
