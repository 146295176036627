import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_loads, load_delete } from '../../../redux/actions/selfActions';

// Utils
import { get_load_status_desc, get_load_type_desc } from '../../../utils/get_status_desc';
import { get_load_status_color } from '../../../utils/get_color_status';

const LoadsTable = (props) => {
	const { messageApi, orgFilters, setIsModalOpen, setUpdate, setLoadToUpdate, pageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const _ls = { ...localStorage };

	const { all_loads_loading, all_loads, load_delete_loading } = useSelector(state => state.self);

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		setLoadToUpdate(record);
	}

	const delete_load = (record) => {
		const filters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(load_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			title: t('routesTable.headers.name'),
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			title: t('loadFileInput.description'),
			dataIndex: 'description',
			key: 'description'
		},
		{
			title: t('loadFileInput.loadType'),
			dataIndex: 'load_type',
			key: 'load_type',
			render: (text) => (
				<Typography.Text>{get_load_type_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.status'),
			dataIndex: 'status',
			key: 'status',
			render: (_, record) => (
				<Tag color={get_load_status_color(record?.status)}>{get_load_status_desc(record?.status)}</Tag>
			)
		},
		{
			title: t('loadStatus.statusTable.date'),
			dataIndex: ['date', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(_ls.i18nextLng, { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.action'),
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('loadForm.edit.title').split(' ')[0]}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<i className='bi bi-pencil-fill'></i>}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('loadForm.delete.popconfirm.title')}
						description={t('loadForm.delete.popconfirm.description')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => delete_load(record)}
						okButtonProps={{
							loading: load_delete_loading
						}}
						okText={t('loadForm.delete.popconfirm.confirm')}
						cancelText={t('loadForm.delete.popconfirm.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<span className='material-symbols-outlined'>delete</span>}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pageSize;
		setSkip(skip);

		const filesFilters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(get_all_loads(filesFilters));
	};

	return (
		<div className='mt-5'>
			<Spin spinning={all_loads_loading} tip={t('loadStatus.statusTable.loading')} size='large'>
				<Table
					columns={columns}
					dataSource={all_loads.loads}
					onChange={handleTableChange}
					pagination={{
						pageSize: pageSize,
						total: all_loads.count,
						showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
					}}
				/>
			</Spin>
		</div>
	)
}

LoadsTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	orgFilters: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setLoadToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default LoadsTable;
