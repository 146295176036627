import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Col, Row, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../../filters/OrgFilters';

// Actions
import { confirm_file, discard_file, get_all_files } from '../../../redux/actions/selfActions';

// Utils
import { get_load_source_desc, get_load_status_desc, get_load_type_desc } from '../../../utils/get_status_desc';
import { get_load_status_color } from '../../../utils/get_color_status';

const LoadStatus = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const _ls = { ...localStorage };

	const { all_files_loading, all_files, file_confirm_loading, file_discard_loading } = useSelector(state => state.self);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [pageSize] = useState(10);

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '' && orgFilters.module !== '') {
			const filters = { ...orgFilters, skip: 0, limit: pageSize };
			dispatch(get_all_files(filters));
		}
	}, [orgFilters]);

	const view = (record) => {
		navigate(`/load-file/${record._id.$oid}/info`)
	}

	const confirm = (record) => {
		dispatch(confirm_file(messageApi, record._id.$oid));
	}

	const discard = (record) => {
		const filters = { ...orgFilters, skip: 0, limit: pageSize };

		dispatch(discard_file(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			title: t('routesTable.headers.name'),
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			title: t('loadFileInput.description'),
			dataIndex: 'description',
			key: 'description'
		},
		{
			title: t('loadFileInput.loadType'),
			dataIndex: 'load_type',
			key: 'load_type',
			render: (text) => (
				<Typography.Text>{get_load_type_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('loadStatusDetails.card.source'),
			dataIndex: 'source',
			key: 'source',
			render: (text) => (
				<Typography.Text>{get_load_source_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.status'),
			dataIndex: 'status',
			key: 'status',
			render: (_, record) => (
				<Tag color={get_load_status_color(record?.status)}>{get_load_status_desc(record?.status)}</Tag>
			)
		},
		{
			title: t('loadStatus.statusTable.date'),
			dataIndex: ['date', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(_ls.i18nextLng, { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.action'),
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('loadStatus.statusTable.actionButtons.details')}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<span className='material-symbols-outlined'>visibility</span>}
							onClick={() => view(record)}
						/>
					</Tooltip>
					{record?.status === 3 &&
						<Space>
							<Tooltip title={t('loadStatus.statusTable.actionButtons.confirm')}>
								<Button
									type='primary'
									shape='circle'
									size='large'
									className='success-btn'
									icon={<span className='material-symbols-outlined'>check_circle</span>}
									loading={file_confirm_loading}
									onClick={() => confirm(record)}
								/>
							</Tooltip>
							<Tooltip title={t('loadStatus.statusTable.actionButtons.discard')}>
								<Button
									type='primary'
									shape='circle'
									size='large'
									danger
									icon={<span className='material-symbols-outlined'>cancel</span>}
									loading={file_discard_loading}
									onClick={() => discard(record)}
								/>
							</Tooltip>
						</Space>
					}
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pageSize;

		const filesFilters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(get_all_files(filesFilters));
	};

	return (
		<Row justify='center'>
			<Col lg={17} md={17} sm={24} xs={24}>
				<Typography.Title className='text-center' level={2}>{t('navbar.options.load.status')}</Typography.Title>

				<OrgFilters
					orgFilters={orgFilters}
					setOrgFilters={setOrgFilters}
					displayFilters={{ organization: true, workspace: true, module: true }}
				/>

				<div className='mt-5'>
					<Spin spinning={all_files_loading} tip={t('loadStatus.statusTable.loading')} size='large'>
						<Table
							columns={columns}
							dataSource={all_files.files}
							onChange={handleTableChange}
							pagination={{
								pageSize: pageSize,
								total: all_files.count,
								showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
							}}
						/>
					</Spin>
				</div>
			</Col>
		</Row>
	);
}

LoadStatus.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default LoadStatus;
