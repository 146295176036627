import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { PoweroffOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { cycle_delete, cycle_end, get_all_cycles } from '../../redux/actions/cyclesActions';

// Utils
import { get_area_type_desc, get_cycle_scope_desc, get_cycle_status_desc } from '../../utils/get_status_desc';
import { get_cycle_status_color } from '../../utils/get_color_status';

const CyclesTable = (props) => {
	const { messageApi, orgFilters, setIsModalOpen, setUpdate, setCycleToUpdate, pageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_cycles_loading, all_cycles, cycle_delete_loading } = useSelector(state => state.cycles);

	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		setCycleToUpdate(record);
	}

	const end_cycle = (record) => {
		const filters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(cycle_end(messageApi, record._id.$oid, filters));
	}

	const delete_cycle = (record) => {
		const filters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(cycle_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			title: t('routesTable.headers.name'),
			dataIndex: 'name',
			key: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.status'),
			dataIndex: 'status',
			key: 'status',
			render: (_, record) => (
				<Tag color={get_cycle_status_color(record?.status)}>{get_cycle_status_desc(record?.status)}</Tag>
			)
		},
		{
			title: t('cycle.type'),
			dataIndex: 'area_type',
			key: 'area_type',
			render: (text) => (
				<Typography.Text>{get_area_type_desc(text)}</Typography.Text>
			)
		},
		{
			title: 'Scope',
			dataIndex: 'scope',
			key: 'scope',
			render: (text) => (
				<Typography.Text>{get_cycle_scope_desc(text)}</Typography.Text>
			)
		},
		{
			title: t('cycle.creationDate'),
			dataIndex: ['date', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(localStorage.getItem('i18nextLng'), { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('cycle.selectStart'),
			dataIndex: ['start', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(localStorage.getItem('i18nextLng'), { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('cycle.selectEnd'),
			dataIndex: ['end', '$date'],
			key: 'date',
			render: (text) => (
				<Typography.Text>{new Date(text).toLocaleString(localStorage.getItem('i18nextLng'), { hour12: true })}</Typography.Text>
			)
		},
		{
			title: t('routesTable.headers.action'),
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('loadForm.edit.title').split(' ')[0]}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<i className='bi bi-pencil-fill'></i>}
							onClick={() => update(record)}
						/>
					</Tooltip>
					{record.status < 5 &&
						<Popconfirm
							title={t('cycle.endAction.popconfirm.title')}
							description={
								<Space direction='vertical' style={{ rowGap: 0 }}>
									<Typography.Text>{t('cycle.endAction.popconfirm.question')}</Typography.Text>
									{/* <Typography.Text>{t('cycle.endAction.popconfirm.description')}</Typography.Text> */}
								</Space>
							}
							icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
							onConfirm={() => end_cycle(record)}
							// okButtonProps={{
							// 	loading: cycle_delete_loading
							// }}
							okText={t('cycle.endAction.popconfirm.confirm')}
							cancelText={t('cycle.endAction.popconfirm.cancel')}
						>
							<Button
								type='primary'
								shape='circle'
								size='large'
								style={{ backgroundColor: '#722ed1' }}
								icon={<PoweroffOutlined style={{ marginTop: '4px' }}/>}
							/>
						</Popconfirm>
					}
					<Popconfirm
						title={t('cycle.delete.popconfirm.title')}
						description={t('cycle.delete.popconfirm.description')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => delete_cycle(record)}
						okButtonProps={{
							loading: cycle_delete_loading
						}}
						okText={t('cycle.delete.popconfirm.confirm')}
						cancelText={t('cycle.delete.popconfirm.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<span className='material-symbols-outlined'>delete</span>}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pageSize;
		setSkip(skip);

		const filesFilters = { ...orgFilters, skip: skip, limit: pageSize };
		dispatch(get_all_cycles(filesFilters));
	};

	return (
		<div className='mt-5'>
			<Spin spinning={all_cycles_loading} tip={t('cycle.loading')} size='large'>
				<Table
					columns={columns}
					dataSource={all_cycles.cycles}
					onChange={handleTableChange}
					pagination={{
						pageSize: pageSize,
						total: all_cycles.count,
						showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('pagination.of')} ${total}`
					}}
				/>
			</Spin>
		</div>
	)
}

CyclesTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	orgFilters: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setCycleToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default CyclesTable;
