import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Col, Divider, Flex, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../../filters/OrgFilters';
import LoadFileForm from './LoadFileForm';

// Actions
import { get_cycles } from '../../../redux/actions/organizationActions';

const LoadFiles = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { self_errors } = useSelector(state => state.self);

	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '', module: '' });
	const [viewErrorDetails, setViewErrorDetails] = useState(false);
	const [displayData, setDisplayData] = useState(false);

	useEffect(() => {
		if (orgFilters.workspace !== '' && orgFilters.module !== '') {
			dispatch(get_cycles({ workspace: orgFilters.workspace, module: orgFilters.module }));
		}
	}, [orgFilters]);

	useEffect(() => {
		if (orgFilters.module === '' || orgFilters.cycle === '') {
			setDisplayData(false);
		}
	}, [orgFilters]);
	
	return (
		<Row justify='center'>
			<Col lg={17} md={17} sm={24} xs={24}>
				<Typography.Title className='text-center' level={2}>Self-Service</Typography.Title>
				<p className='text-center mb-4'>{t('landing.instruction')}</p>

				<OrgFilters
					orgFilters={orgFilters}
					setOrgFilters={setOrgFilters}
					displayFilters={{ organization: true, workspace: true, module: true }}
				/>
				{!displayData &&
					<Row className='mt-4' justify='center'>
						<Col className='gutter-row' lg={4} md={6}>
							<Button className='submit-btn' block onClick={() => setDisplayData(true)}>{t('landing.continue')}</Button>
						</Col>
					</Row>
				}

				{displayData
					?	<div>
							<Divider />
							<Typography.Title className='text-center mt-4 mb-3' level={4}>{t('loadFile.load')}</Typography.Title>

							{self_errors.hasOwnProperty('upload_file') &&
								<div style={{ marginBottom: '1.5rem' }}>
									<Alert
										message={
											<Flex justify='space-between'>
												<Typography.Text style={{ fontSize: '16px' }}>
													{t('loadFile.msg.error')}
												</Typography.Text>
												<Button size='small' danger onClick={() => setViewErrorDetails(!viewErrorDetails)}>
													{t('loadStatus.statusTable.actionButtons.details')}
												</Button>
											</Flex>
										}
										description={viewErrorDetails ? <pre>{JSON.stringify(self_errors.upload_file.msg, null, 2)}</pre> : <p hidden>Error</p>}
										type='error'
										showIcon
									/>
								</div>
							}
							<LoadFileForm messageApi={messageApi} />
						</div>
					:	<img className='image' src={process.env.PUBLIC_URL + '/assets/selfservice-img.svg'} alt='Selfservice' />
				}
			</Col>
		</Row>
	);
}

LoadFiles.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default LoadFiles;
