import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { clear_self_errors_errors, self_error_create, self_error_update } from '../../../redux/actions/selfErrorsActions';

// Utils
import { create_select_options } from '../../../utils/create_select_options';
import isEmpty from '../../../utils/isEmpty';

const ErrorModal = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, orgFilters, update, errorToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { organizations } = useSelector(state => state.organization);

	const { self_error_create_loading, self_error_update_loading, self_errors_errors } = useSelector(state => state.self_errors);
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(errorToUpdate)) {
			form.setFieldsValue({
				alias: errorToUpdate.alias,
				name: errorToUpdate.name,
				description: errorToUpdate.description
			});
		}
	}, [errorToUpdate]);

	const handleCancel = () => {
		form.resetFields();
		setIsModalOpen(false);
		dispatch(clear_self_errors_errors());
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					values = {
						...values,
						organization: values.organization ?? orgFilters.organization
					};
					
					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(self_error_create(messageApi, values, handleCancel, filters));
				}
				else {
					const filters = { ...orgFilters, skip: skip, limit: pageSize };
					dispatch(self_error_update(messageApi, errorToUpdate?._id?.$oid, values, handleCancel, filters));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('errorForm.create.title') : t('errorForm.edit.title')}
			centered
			open={isModalOpen}
			onCancel={handleCancel}
			maskClosable={false}
			width={700}
			footer={[
				<Button key='back' onClick={handleCancel}>
					{t('errorForm.cancel')}
				</Button>,
				<Button
					key='submit'
					type='primary'
					loading={!update ? self_error_create_loading : self_error_update_loading}
					onClick={onOk}
				>
					{!update ? t('errorForm.create.title').split(' ')[0] : t('errorForm.edit.title').split(' ')[0]}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 4 }}
				form={form}
			>
				{self_errors_errors.hasOwnProperty('self_error_create') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('errorForm.create.msg.error')} type='error' showIcon />
					</div>
				}

				{self_errors_errors.hasOwnProperty('self_error_update') &&
					<div style={{ marginBottom: '1.5rem' }}>
						<Alert message={t('errorForm.edit.msg.error')} type='error' showIcon />
					</div>
				}

				{!update &&
					<Fragment>
						{orgFilters.organization === '' &&
							<Form.Item
								label={t('filters.organization')}
								name='organization'
								rules={[
									{
										required: true,
										message: t('errorForm.validationMsg.organization'),
									}
								]}
							>
								<Select
									showSearch
									placeholder={t('errorForm.placeholder.organization')}
									optionFilterProp='children'
									filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
									options={create_select_options(organizations, 'name')}
								/>
							</Form.Item>
						}
					</Fragment>
				}
				<Form.Item
					label={t('errorForm.inputs.alias')}
					name='alias'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.alias'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.alias')} />
				</Form.Item>
				<Form.Item
					label={t('routesTable.headers.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.name'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.name')} />
				</Form.Item>
				<Form.Item
					label={t('loadFileInput.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('errorForm.validationMsg.description'),
						}
					]}
				>
					<Input placeholder={t('errorForm.placeholder.description')} />
				</Form.Item>
				{!update &&
					<Form.Item
						label={t('errorForm.inputs.value')}
						name='value'
						rules={[
							{
								required: true,
								message: t('errorForm.validationMsg.value.required'),
							},
							{
								type: 'number',
								min: 1,
								message: t('errorForm.validationMsg.value.min'),
							}
						]}
					>
						<InputNumber
							style={{ width: '100%' }}
							placeholder={t('errorForm.placeholder.value')}
							min={1}
						/>
					</Form.Item>
				}
			</Form>
		</Modal>
	)
}

ErrorModal.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	orgFilters: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	errorToUpdate: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default ErrorModal;
