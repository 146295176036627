import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Packages
import { Card, Col, Divider, Flex, Row, Skeleton, Space, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

// Utils
import { get_route_color_status } from '../../utils/get_color_status';
import { get_route_status_desc } from '../../utils/get_status_desc';

const RouteInfoCard = () => {
	const { t } = useTranslation();

	const { route_data_loading, route_data } = useSelector(state => state.routes);

	const [size, setSize] = useState(100);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 576) {
				setSize('small');
			} else if (window.innerWidth < 768) {
				setSize('middle');
			} else {
				setSize(100);
			}
		};

		// Set size at the initial load
		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Flex justify='center' style={{ marginBottom: '1.5rem' }}>
			<Card
				bordered={false}
				style={{ width: 1000 }}
			>
				<Skeleton loading={route_data_loading} active>
					<Row style={{ textAlign: 'center'}}>
						<Col span={24}>
							<Space direction='vertical'>
								<Typography.Title level={4} copyable={{ text: route_data?._id?.$oid, tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')] }}>
									{t('filters.route')}: {route_data?.code}
								</Typography.Title>
								<Typography.Text type='secondary'>{route_data?.description}</Typography.Text>
							</Space>

							<Divider />

							<Typography.Title level={5}>{t('filters.areas')}</Typography.Title>
							<Space className='responsive-space' direction='horizontal' size={size}>
								{route_data?.region !== undefined &&
									<Typography.Text>
										<strong>{t('filters.region')}:</strong> {route_data?.region?.name}
									</Typography.Text>
								}
								{route_data?.zone !== undefined &&
									<Typography.Text>
										<strong>{t('filters.zone')}:</strong> {route_data?.zone?.name}
									</Typography.Text>
								}
								{route_data?.depot !== undefined &&
									<Typography.Text>
										<strong>{t('filters.depot')}:</strong> {route_data?.depot?.name}
									</Typography.Text>
								}
								{route_data?.group !== undefined &&
									<Typography.Text>
										<strong>{t('filters.group')}:</strong> {route_data?.group?.name}
									</Typography.Text>
								}								
							</Space>

							<Divider />
							<Space className='responsive-space' direction='horizontal' size={size}>
								<Typography.Text>
									<strong>{t('routesTable.headers.locations')}:</strong> {route_data?.locations?.length || 0}
								</Typography.Text>
								<Typography.Text>
									<strong>{t('routesTable.headers.status')}:</strong> <Tag color={get_route_color_status(route_data?.status)}>{get_route_status_desc(route_data?.status)}</Tag>
								</Typography.Text>
							</Space>
						</Col>
					</Row>
				</Skeleton>
			</Card>
		</Flex>
	)
}

export default RouteInfoCard;
