import Axios from 'axios';
import i18next from 'i18next';

import {
	ORGANIZATIONS_GET,
	ORGANIZATIONS_ERROR,
	ORG_INFO_GET,
	ORG_INFO_ERROR,
	WORKSPACES_GET,
	WORKSPACES_ERROR,
	MODULES_GET,
	MODULES_ERROR,
	CYCLES_GET,
	CYCLES_ERROR,
	ORG_FILTERS_SET
} from '../types';

import create_query_params from '../../utils/create_query_params';
import { get_cycle_status_desc } from '../../utils/get_status_desc';
import { epoch_to_local_date } from '../../utils/dates_handler';

export const get_organizations = () => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations';

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORGANIZATIONS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORGANIZATIONS_ERROR,
			payload: { type: 'organizations', msg: err.message }
		})
	})
}

export const get_organization_info = (org_id) => dispatch => {
	// console.log(org_id)
	let url = process.env.REACT_APP_SERVER_URL + `/api/organizations/${org_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ORG_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ORG_INFO_ERROR,
			payload: { type: 'org_info', msg: err.message }
		})
	})
}

export const get_workspaces = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/organizations/workspaces?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: WORKSPACES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: WORKSPACES_ERROR,
			payload: { type: 'wokspaces', msg: err.message }
		})
	})
}

export const get_modules = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/modules?module_type=2&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: MODULES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: MODULES_ERROR,
			payload: { type: 'modules', msg: err.message }
		})
	})
}

const updateCyclesName = (cycles) => {
	cycles.sort((prev, next)=> (prev.date.$date < next.date.$date ? 1 : -1));

	cycles.forEach((cycle) => {
		cycle.name = `${cycle.name} - ${i18next.t('cycle.selectStart')} ${epoch_to_local_date(cycle.start.$date)} - ${i18next.t('cycle.selectEnd')} ${epoch_to_local_date(cycle.end.$date)} - ${get_cycle_status_desc(cycle.status)}`;
	})

	return cycles;
}

export const get_cycles = (filters) => dispatch => {
	// console.log(filters)
	let url = process.env.REACT_APP_SERVER_URL + '/api/cycles/data?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: CYCLES_GET,
			payload: updateCyclesName(res.data)
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CYCLES_GET,
			payload: []
		})
		dispatch({
			type: CYCLES_ERROR,
			payload: { type: 'cycles', msg: err.message }
		})
	})
}

export const set_org_filters = (orgFilters) => dispatch => {
	dispatch({
		type: ORG_FILTERS_SET,
		payload: orgFilters
	});
}
