import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_CYCLES_DATA,
	CLEAR_ALL_CYCLES_ERRORS,
	CLEAR_CYCLES_ERROR,
	SET_CYCLE_ID,
	CYCLE_CREATE_LOADING,
	CYCLE_CREATE_ERROR,
	ALL_CYCLES_LOADING,
	ALL_CYCLES_GET,
	ALL_CYCLES_ERROR,
	CYCLE_UPDATE_LOADING,
	CYCLE_UPDATE_ERROR,
	CYCLE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_cycles_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_CYCLES_DATA
	});
}

export const clear_cycles_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_CYCLES_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_CYCLES_ERRORS
		});
	}
}

export const set_cycle_create_id = (cycleId) => dispatch => {
	dispatch({
		type: SET_CYCLE_ID,
		payload: cycleId
	});
}

export const cycle_create = (messageApi, cycleBody, handleCancel, filters) => dispatch => {
	dispatch(clear_cycles_errors('cycle_create'));

	dispatch({
		type: CYCLE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/cycles/create';

	Axios.post(url, cycleBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_cycle_create_id(res.data.cycle));

		messageApi.success(i18next.t('cycle.create.success'), 4);

		dispatch({
			type: CYCLE_CREATE_LOADING,
			payload: false
		});
		
		handleCancel();

		dispatch(get_all_cycles(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CYCLE_CREATE_ERROR,
			payload: { type: 'cycle_create', msg: err.message }
		});

		dispatch({
			type: CYCLE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_cycles = (filters) => dispatch => {
	dispatch({
		type: ALL_CYCLES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/cycles?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newCyclesArray = add_key_prop(res.data.cycles);
		res.data.cycles = newCyclesArray;

		dispatch({
			type: ALL_CYCLES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_CYCLES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_CYCLES_ERROR,
			payload: { type: 'get_all_cycles', msg: err.message }
		});

		dispatch({
			type: ALL_CYCLES_LOADING,
			payload: false
		});
	})
}

export const cycle_update = (messageApi, cycleId, cycleBody, handleCancel, filters) => dispatch => {
	dispatch({
		type: CYCLE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/cycles/${cycleId}/update`;

	Axios.put(url, cycleBody)
	.then((res) => {
		messageApi.success(i18next.t('cycle.edit.success'), 4);

		dispatch({
			type: CYCLE_UPDATE_LOADING,
			payload: false
		});

		handleCancel();

		dispatch(get_all_cycles(filters));
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CYCLE_UPDATE_ERROR,
			payload: { type: 'cycle_update', msg: err.message }
		});

		dispatch({
			type: CYCLE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const cycle_end = (messageApi, cycleId, filters) => dispatch => {
	const hideLoadingMsg = messageApi.loading(i18next.t('cycle.endAction.msg.loading'), 0);

	let url = process.env.REACT_APP_SERVER_URL + `/api/cycles/${cycleId}/end`;

	Axios.get(url)
	.then((res) => {
		// console.log(res);
		hideLoadingMsg();
		messageApi.success(i18next.t('cycle.endAction.msg.success'), 4);

		dispatch(get_all_cycles(filters));
	}).catch((err) => {
		// console.log(err);
		hideLoadingMsg();
		messageApi.success(i18next.t('cycle.endAction.msg.error'), 4);
	})
}

export const cycle_delete = (messageApi, cycleId, filters) => dispatch => {
	dispatch({
		type: CYCLE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/cycles/${cycleId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('cycle.delete.msg.success'), 4);

		dispatch({
			type: CYCLE_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_cycles(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('cycle.delete.msg.error'), 4);

		dispatch({
			type: CYCLE_DELETE_LOADING,
			payload: false
		});
	})
}
